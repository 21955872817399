import React from 'react';
import { User } from '../gql';

interface MeProviderProps {
  user: User;
  children: React.ReactNode;
}

const meContext = React.createContext<User | null>(null);

export const MeProvider = ({ user, children }: MeProviderProps) => {
  /** */
  return <meContext.Provider value={user}>{children}</meContext.Provider>;
};

export const useMe = () => {
  const ctx = React.useContext(meContext);
  if (!ctx) throw new Error('useMe must be used within a MeProvider');
  return ctx;
};
