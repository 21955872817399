import { gql } from '@apollo/client';
import { Box, Dialog, IconButton, Tab, Tabs } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  endOfDay,
  setHours,
  setMinutes,
  startOfDay,
  subSeconds,
} from 'date-fns';
import React from 'react';
import { useMe } from '../auth/me-context';
import RecoveryFormCard, {
  RecoveryFormValue,
  RecoveryFormBag,
} from '../components/events/recoveries/RecoveryFormCard';
import TimeLogFormCard, {
  TimeLogFormBag,
  TimeLogFormValue,
} from '../components/events/time-logs/TimeLogFormCard';
import VacationFormCard, {
  VacationFormBag,
  VacationFormValues,
} from '../components/events/vacations/VacationFormCard';
import CircularProgressBox from '../components/utils/CircularProgressBox';
import {
  useCreateTimeLogMutation,
  useCreateTimeOffMutation,
  useProjectsQuery,
} from '../gql';
import { SlotInfo } from '../interfaces';

export interface CreateEventDialogContainerProps {
  slotInfo?: SlotInfo;
  onClose: () => void;
}

const CreateEventDialogContainer = ({
  slotInfo,
  onClose,
}: CreateEventDialogContainerProps) => {
  const me = useMe();
  const { data: projectsData, loading: projectsLoading } = useProjectsQuery();
  const [createTimeOff, { loading: createTimeOffLoading }] =
    useCreateTimeOffMutation();
  const [createTimeLog, { loading: createTimeLogLoading }] =
    useCreateTimeLogMutation();
  /** */
  const [type, setType] = React.useState<'timelog' | 'vacation' | 'recovery'>(
    'timelog',
  );
  const loading =
    projectsLoading || createTimeOffLoading || createTimeLogLoading;
  const myProjects = ((projectsData && projectsData.projects) || []).filter(
    (p) => p.users && p.users.map((u) => u.id).includes(me.id),
  );

  /** */
  const _onClose = () => {
    if (loading) return;
    onClose();
  };

  const onTimeOffSumbit = async (
    values: VacationFormValues | RecoveryFormValue,
    bag: VacationFormBag | RecoveryFormBag,
  ) => {
    try {
      await createTimeOff({
        variables: { input: { userId: me.id, ...values } },
        refetchQueries: ['Me'],
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              timeOff(existing = []) {
                const newCacheRef = cache.writeFragment({
                  data: data && data.createTimeOff,
                  fragment: gql`
                    fragment NewTimeOff on TimeOff {
                      id
                      type
                    }
                  `,
                });
                return [...existing, newCacheRef];
              },
            },
          });
        },
      });
      onClose();
    } catch (error) {
      bag.setStatus({ apolloError: error });
      bag.setSubmitting(false);
    }
  };

  const onTimeLogSumbit = async (
    values: TimeLogFormValue,
    bag: TimeLogFormBag,
  ) => {
    try {
      await createTimeLog({
        variables: { input: { userId: me.id, ...values } },
        refetchQueries: ['Me'],
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              timeLogs(existing = []) {
                const newCacheRef = cache.writeFragment({
                  data: data && data.createTimeLog,
                  fragment: gql`
                    fragment NewTimeLog on TimeLog {
                      id
                      type
                    }
                  `,
                });
                return [...existing, newCacheRef];
              },
            },
          });
        },
      });
      onClose();
    } catch (error) {
      bag.setStatus({ apolloError: error });
      bag.setSubmitting(false);
    }
  };

  /** */
  React.useEffect(() => {
    if (!slotInfo) {
      setType('timelog');
    } else {
      if (slotInfo.slots.length > 1) {
        setType('vacation');
      } else {
        setType('timelog');
      }
    }
  }, [slotInfo]);

  /** */
  return (
    <Dialog fullWidth maxWidth="md" open={!!slotInfo} onClose={_onClose}>
      <Box p={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Tabs
            centered
            value={type}
            onChange={(_, v) => {
              setType(v);
            }}
            aria-label="simple tabs example"
          >
            <Tab
              value="timelog"
              label="Session de travail"
              disabled={loading}
            />
            <Tab value="vacation" label="Congé" disabled={loading} />
            <Tab value="recovery" label="Récupération" disabled={loading} />
          </Tabs>

          <Box>
            <IconButton disabled={loading} onClick={_onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>

      {slotInfo && (
        <>
          {type === 'timelog' && (
            <>
              {projectsLoading ? (
                <CircularProgressBox />
              ) : (
                <TimeLogFormCard
                  initialValues={{
                    startDate: setMinutes(
                      setHours(new Date(slotInfo.slots[0]), 9),
                      0,
                    ),
                    endDate: setMinutes(
                      setHours(new Date(slotInfo.slots[0]), 17),
                      0,
                    ),
                  }}
                  projects={myProjects}
                  onSubmit={onTimeLogSumbit}
                />
              )}
            </>
          )}

          {type === 'vacation' && (
            <VacationFormCard
              initialValues={{
                startDate: startOfDay(new Date(slotInfo.slots[0])),
                endDate: subSeconds(
                  endOfDay(new Date(slotInfo.slots[slotInfo.slots.length - 1])),
                  1,
                ),
              }}
              onSubmit={onTimeOffSumbit}
            />
          )}

          {type === 'recovery' && (
            <RecoveryFormCard
              initialValues={{
                startDate: setMinutes(
                  setHours(new Date(slotInfo.slots[0]), 9),
                  0,
                ),
                endDate: setMinutes(
                  setHours(new Date(slotInfo.slots[0]), 17),
                  0,
                ),
              }}
              onSubmit={onTimeOffSumbit}
            />
          )}
        </>
      )}
    </Dialog>
  );
};

export default CreateEventDialogContainer;
