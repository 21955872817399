import { Box, IconButton, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { Link } from 'react-router-dom';
import { useMe } from '../auth/me-context';
import DefaultLayout from '../components/layout/DefaultLayout';
import ProjectPreviewCard from '../components/projects/ProjectPreviewCard';
import { useProjectsQuery } from '../gql';
import { isAdmin } from '../utils/parser';

export interface ProjectsPageProps {}

const ProjectsPage = (props: ProjectsPageProps) => {
  const { data } = useProjectsQuery();
  const me = useMe();
  const projects = data && data.projects;
  const yourProjects =
    projects &&
    projects.filter((p) => p.users.map((u) => u.id).includes(me.id));
  const otherProjects =
    projects &&
    projects.filter((p) => !p.users.map((u) => u.id).includes(me.id));

  /** */
  return (
    <DefaultLayout
      title={
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="h2">Projets</Typography>
          </Box>
          <Box>
            {isAdmin(me) && (
              <Link to="/create/project">
                <IconButton>
                  <AddIcon />
                </IconButton>
              </Link>
            )}
          </Box>
        </Box>
      }
    >
      <Box my={4}>
        {/* {projects &&
          projects.map((project) => (
            <Box key={project.id} my={2}>
              <ProjectPreviewCard project={project}></ProjectPreviewCard>
            </Box>
          ))} */}

        {projects && (
          <>
            {yourProjects && (
              <Box mb={4}>
                <Typography variant="h5" color="textSecondary">
                  Dont vous êtes membre
                </Typography>
                {yourProjects.map((project) => (
                  <Box key={project.id} my={2}>
                    <ProjectPreviewCard project={project}></ProjectPreviewCard>
                  </Box>
                ))}
              </Box>
            )}

            {otherProjects && (
              <Box mb={4}>
                <Typography variant="h5" color="textSecondary">
                  Autres
                </Typography>
                {otherProjects.map((project) => (
                  <Box key={project.id} my={2}>
                    <ProjectPreviewCard project={project}></ProjectPreviewCard>
                  </Box>
                ))}
              </Box>
            )}
          </>
        )}
      </Box>
    </DefaultLayout>
  );
};

export default ProjectsPage;
