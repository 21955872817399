import {
  Box,
  Card,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useMe } from '../auth/me-context';
import DefaultLayout from '../components/layout/DefaultLayout';
import ProjectAvatar from '../components/projects/ProjectAvatar';
import UserAvatar from '../components/users/UserAvatar';
import { useProjectQuery } from '../gql';
import { isAdmin } from '../utils/parser';

const ProjectPage = () => {
  let { id: idStr } = useParams<{ id: string }>();
  const projectId = parseInt(idStr!, 10);
  const {
    data: projectData,
    loading: projectLoading,
    // error: projectError,
  } = useProjectQuery({
    variables: { id: projectId },
  });
  const me = useMe();
  /** */
  const project = projectData && projectData.project;

  /** */
  return (
    <DefaultLayout
      loading={projectLoading}
      title={
        project && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" alignItems="center">
                <Box mr={2}>
                  <ProjectAvatar project={project} size="big" />
                </Box>
                <Typography variant="h2">{project.name}</Typography>
              </Box>

              {isAdmin(me) && (
                <Link to={`/projects/${project.id}/edit`}>
                  <IconButton>
                    <EditIcon />
                  </IconButton>
                </Link>
              )}
            </Box>

            {project.pitch && (
              <Typography variant="h6" color="textSecondary">
                {project.pitch}
              </Typography>
            )}
          </>
        )
      }
    >
      {project && (
        <>
          {project.users && project.users.length > 0 && (
            <Box my={4}>
              <Card>
                <CardHeader title="Membres"></CardHeader>
                <List dense>
                  {project.users.map((user) => (
                    <ListItem key={user.id}>
                      <ListItemIcon>
                        <UserAvatar size="small" user={user}></UserAvatar>
                      </ListItemIcon>
                      <ListItemText
                        primary={`${user.firstName} ${user.lastName}`}
                      ></ListItemText>
                    </ListItem>
                  ))}
                </List>
              </Card>
            </Box>
          )}
        </>
      )}
    </DefaultLayout>
  );
};

export default ProjectPage;
