import { useApolloClient } from '@apollo/client';
import { LinearProgress } from '@material-ui/core';
import React from 'react';
import { JWT_LOCAL_STORAGE_KEY } from '../constants';
import { useLoginMutation, useMeLazyQuery, User } from '../gql';
import { MeProvider } from './me-context';

interface AuthContext {
  user?: User;
  login: (email: string, password: string) => void;
  logout: () => void;
}

export interface AuthProviderProps {
  children: React.ReactNode;
}

const authContext = React.createContext<AuthContext | null>(null);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const client = useApolloClient();
  const [
    getMe,
    { data: meData, loading: meLoading, error: meError, called: meCalled },
  ] = useMeLazyQuery();
  const [loginMutation] = useLoginMutation();
  /** */
  const needCallMe = !!localStorage.getItem(JWT_LOCAL_STORAGE_KEY);
  const user = meData && (meData.me as User);

  const login = async (email: string, password: string) => {
    const { data } = await loginMutation({
      variables: { input: { email, password } },
    });
    const token = data && data.login;
    if (token) {
      localStorage.setItem(JWT_LOCAL_STORAGE_KEY, token);
    }
    getMe();
  };

  const logout = async () => {
    localStorage.removeItem(JWT_LOCAL_STORAGE_KEY);
    await client.clearStore();
    document.location.reload();
  };

  /** */
  React.useEffect(() => {
    const token = localStorage.getItem(JWT_LOCAL_STORAGE_KEY);
    if (token) {
      getMe();
    }
  }, [getMe]);

  React.useEffect(() => {
    if (meError) {
    }
  }, [meError]);

  /** */
  return (
    <authContext.Provider value={{ user, login, logout }}>
      {(needCallMe && !meCalled) || meLoading ? (
        <LinearProgress />
      ) : (
        <>{user ? <MeProvider user={user}>{children}</MeProvider> : children}</>
      )}
    </authContext.Provider>
  );
};

export const useAuth = () => {
  const ctx = React.useContext(authContext);
  if (!ctx) throw new Error('useAuth must be used within a AuthProvider');
  return ctx;
};
