import { Box, Chip, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { User as GQLUser } from '../../gql';
import UserAvatar from './UserAvatar';

type User = Pick<GQLUser, 'id' | 'firstName' | 'lastName'>;

export interface UsersSelectorProps {
  users: User[];
  initialValues?: number[];
  disabled?: boolean;
  onChange: (users: User[]) => void;
}

const UsersSelector = ({
  users,
  initialValues = [],
  disabled = false,
  onChange,
}: UsersSelectorProps) => {
  const [inputValue, setInputValue] = React.useState('');
  const [selectedUser, setSelectedUser] = React.useState<User | null>(null);
  const [addedUsers, setAddedUsers] = React.useState<User[]>(
    users.filter((u) => initialValues.includes(u.id)),
  );

  const options = users.filter(
    (u) => !addedUsers.map((au) => au.id).includes(u.id),
  );

  /** */
  React.useEffect(() => {
    onChange(addedUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedUsers]);

  /** */
  return (
    <>
      {addedUsers.length > 0 && (
        <Box mb={4} display="flex" flexWrap="wrap">
          {addedUsers.map((user) => (
            <Box key={user.id} mr={1} mb={1}>
              <Chip
                variant="outlined"
                avatar={
                  <Box ml={1}>
                    <UserAvatar size="small" user={user} />
                  </Box>
                }
                label={`${user.firstName} ${user.lastName}`}
                disabled={disabled}
                onDelete={() => {
                  setAddedUsers(addedUsers.filter((u) => u.id !== user.id));
                }}
              />
            </Box>
          ))}
        </Box>
      )}

      {options.length > 0 && (
        <Autocomplete
          inputValue={inputValue}
          value={selectedUser}
          options={options}
          getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
          renderOption={(option) => (
            <>
              <Box mr={2} component="span">
                <UserAvatar size="small" user={option} />
              </Box>
              <span>{`${option.firstName} ${option.lastName}`}</span>
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Selectionner un collègue"
              variant="outlined"
            />
          )}
          disabled={disabled}
          onInputChange={(_, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={(_, newValue) => {
            if (newValue) {
              setAddedUsers([...addedUsers, newValue]);
            }
            setSelectedUser(null);
            setInputValue('');
          }}
        />
      )}
    </>
  );
};

export default UsersSelector;
