import { Box, IconButton, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { Link } from 'react-router-dom';
import { useMe } from '../auth/me-context';
import DefaultLayout from '../components/layout/DefaultLayout';
import UserPreviewCard from '../components/users/UserPreviewCard';
import { useUsersQuery } from '../gql';
import { isAdmin } from '../utils/parser';

export interface UsersPageProps {}

const UsersPage = (props: UsersPageProps) => {
  const { data } = useUsersQuery();
  const me = useMe();
  const users = data && data.users;

  return (
    <DefaultLayout
      title={
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="h2">Collègues</Typography>
          </Box>
          <Box>
            {isAdmin(me) && (
              <Link to="/create/user">
                <IconButton>
                  <AddIcon />
                </IconButton>
              </Link>
            )}
          </Box>
        </Box>
      }
    >
      <Box my={4}>
        {users &&
          users.map((user) => (
            <Box key={user.id} my={2}>
              <UserPreviewCard user={user}></UserPreviewCard>
            </Box>
          ))}
      </Box>
    </DefaultLayout>
  );
};

export default UsersPage;
