import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';

export interface CircularProgressBoxProps {}

const CircularProgressBox = (props: CircularProgressBoxProps) => {
  return (
    <Box p={2} display="flex" justifyContent="center">
      <CircularProgress />
    </Box>
  );
};

export default CircularProgressBox;
