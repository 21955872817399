import { isAfter } from 'date-fns';
import { Contract } from '../gql';
import { formatFr } from './date';

export const formatContratDateRange = ({
  startDate,
  endDate,
}: Pick<Contract, 'startDate' | 'endDate'>) => {
  console.log(endDate, new Date());
  if (isAfter(endDate, new Date())) {
    return `Depuis le ${formatFr(startDate, 'd MMMM yyyy')}`;
  }
  return `Du ${formatFr(startDate, 'd MMMM yyyy')} au  ${formatFr(
    endDate,
    'd MMMM yyyy',
  )}`;
};
