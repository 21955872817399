import {
  format as formatFns,
  formatDistance as formatDistanceFns,
  formatDistanceStrict as formatDistanceStrictFns,
  formatDistanceToNow as formatDistanceToNowFns,
  parse,
  startOfWeek,
  getDay,
} from 'date-fns';
import fr from 'date-fns/locale/fr';
import { dateFnsLocalizer } from 'react-big-calendar';

export const formatFr = (date: Date, format: string) => {
  return formatFns(date, format, { locale: fr });
};

export const formatDistanceFr = (
  date: number | Date,
  baseDate: number | Date,
) => {
  return formatDistanceFns(date, baseDate, { locale: fr });
};

export const formatDistanceStrictFr = (
  date: number | Date,
  baseDate: number | Date,
) => {
  return formatDistanceStrictFns(date, baseDate, { locale: fr });
};

export const formatDistanceToNowFr = (date: number | Date) => {
  return formatDistanceToNowFns(date, { locale: fr });
};

export const formatDistanceToNowStrictFr = (date: number | Date) => {
  return formatDistanceStrictFns(new Date(), date, { locale: fr });
};

export const calendarDateFnsLocalizer = dateFnsLocalizer({
  format: formatFns,
  parse,
  startOfWeek,
  getDay,
  locales: {
    fr,
  },
});
