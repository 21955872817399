import { createTheme, ThemeOptions } from '@material-ui/core';

const options: ThemeOptions = {
  palette: {
    type: 'dark',
    background: {
      default: '#264653',
      paper: '#356071',
    },
    primary: {
      main: '#2a9d8f',
    },
    secondary: {
      main: '#e9c46a',
    },
  },
};

const theme = createTheme(options);

export default theme;
