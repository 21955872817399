import {
  Contract,
  Holiday,
  Project as GQLProject,
  TimeLog as GQLTimeLog,
  TimeOff as GQLTimeOff,
  TimeOffType,
  User as GQLUser,
  UserRole,
} from '../gql';
import { CalendarEvent } from '../interfaces';
import { formatDistanceStrictFr } from './date';

export const timeOffToCalendarEvent = (
  tf: Pick<GQLTimeOff, 'id' | 'type' | 'startDate' | 'endDate'> & {
    user: Pick<GQLUser, 'id' | 'firstName' | 'lastName'>;
  },
): CalendarEvent => {
  let title = '';

  if (tf.type === TimeOffType.Vacation) {
    title = `Congés de ${tf.user.firstName}`;
  } else if (tf.type === TimeOffType.WithoutPay) {
    title = `Congés sans solde de ${tf.user.firstName}`;
  } else if (tf.type === TimeOffType.Recovery) {
    title = `Récup de ${tf.user.firstName}`;
  }

  return {
    title: title,
    start: tf.startDate,
    end: tf.endDate,
    resource: { calendarEventType: 'timeoff', ...tf },
  };
};

export const timeLogToCalendarEvent = (
  tl: Pick<
    GQLTimeLog,
    'id' | 'label' | 'startDate' | 'endDate' | 'projectId'
  > & {
    project?: Pick<GQLProject, 'name'> | null;
  } & {
    user: Pick<GQLUser, 'id'>;
  },
): CalendarEvent => {
  let title = '';

  if (tl.project) {
    title = `[${tl.project.name.slice(0, 3)}] `;
  }
  if (tl.label) {
    title = `${title} ${tl.label} : `;
  }
  title = `${title} ~${formatDistanceStrictFr(tl.startDate, tl.endDate)}`;

  return {
    title: title,
    start: tl.startDate,
    end: tl.endDate,
    resource: { calendarEventType: 'timelog', ...tl },
  };
};

export const holidayToCalendarEvent = (holiday: Holiday): CalendarEvent => {
  return {
    title: holiday.label,
    start: holiday.startDate,
    end: holiday.endDate,
    resource: { calendarEventType: 'holiday', ...holiday },
  };
};

export const isAdmin = (user: Pick<GQLUser, 'role'>) => {
  return user.role === UserRole.Admin;
};

export const userAvailableRecoveryTime = ({
  contracts,
}: {
  contracts: Pick<Contract, 'availableRecoveryTime'>[];
}) => contracts.reduce((acc, c) => acc + c.availableRecoveryTime, 0);

// export const userVacationBalance = ({
//   contracts,
// }: {
//   contracts: Pick<Contract, 'vacationDaysEarned' | 'vacationDaysSpent'>[];
// }) =>
//   contracts.reduce(
//     (acc, c) => acc + (c.vacationDaysEarned - c.vacationDaysSpent),
//     0,
//   );
