var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import every from "lodash.every";
import flatMap from "lodash.flatmap";
import fromPairs from "lodash.frompairs";
import uniqBy from "lodash.uniqby";
import { isFieldNode, isInlineFragmentNode } from "./node-types";
function uniqueNodes(nodes) {
    return uniqBy(nodes, (fn) => JSON.stringify(fieldNodeKeyTuple(fn)));
}
function fieldNodeKeyTuple(fn) {
    var _a, _b, _c;
    const alias = (_a = fn.alias) === null || _a === void 0 ? void 0 : _a.value;
    const name = fn.name.value;
    const selections = (_c = (_b = fn.selectionSet) === null || _b === void 0 ? void 0 : _b.selections) !== null && _c !== void 0 ? _c : [];
    const selectionKeys = selections.map((sn) => JSON.stringify(selectionKeyTuples(sn))).sort();
    return [alias, name, selectionKeys];
}
function selectionKeyTuples(sn) {
    if (isFieldNode(sn))
        return { field: fieldNodeKeyTuple(sn) };
    if (isInlineFragmentNode(sn)) {
        return { inlineFragments: sn.selectionSet.selections.map((sn) => selectionKeyTuples(sn)) };
    }
    return { namedFragment: sn.name.value };
}
function getCleanedSelections(selections, fragmentMap) {
    return flatMap(selections, (sn) => {
        if (isFieldNode(sn))
            return [sn];
        if (isInlineFragmentNode(sn))
            return sn.selectionSet.selections;
        const nodeOrSelectionList = fragmentMap[sn.name.value];
        if (!nodeOrSelectionList)
            return [];
        return Array.isArray(nodeOrSelectionList) ? nodeOrSelectionList : nodeOrSelectionList.selectionSet.selections; // fragment node
    });
}
function getResolvedFieldNodes(fieldNodes, fragmentMap) {
    return fieldNodes.map((fn) => {
        const { selectionSet } = fn, restFn = __rest(fn, ["selectionSet"]);
        if (!selectionSet || !selectionSet.selections || !selectionSet.selections.length) {
            return Object.assign({}, restFn);
        }
        const replacedSelections = replaceFragmentsOn(selectionSet.selections, fragmentMap);
        return Object.assign(Object.assign({}, restFn), { selectionSet: Object.assign(Object.assign({}, selectionSet), { selections: replacedSelections }) });
    });
}
export function replaceFragmentsOn(selections, fragmentMap) {
    const cleaned = getCleanedSelections(selections, fragmentMap);
    if (!every(cleaned, isFieldNode)) {
        return replaceFragmentsOn(cleaned, fragmentMap);
    }
    const resolved = getResolvedFieldNodes(cleaned, fragmentMap);
    const uniqueList = uniqueNodes(resolved);
    return uniqueList;
}
export function fragmentMapFrom(fragments) {
    const initialMap = fromPairs(fragments.map((f) => [f.name.value, f]));
    return fromPairs(fragments.map((f) => {
        const fieldNodes = replaceFragmentsOn(f.selectionSet.selections, initialMap);
        return [f.name.value, fieldNodes];
    }));
}
