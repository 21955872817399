import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  LinearProgress,
  TextField as MuiTextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  DatePicker as MuiDatePicker,
  TimePicker as MuiTimePicker,
} from '@material-ui/pickers';
import { getHours, getMinutes, isAfter, setHours, setMinutes } from 'date-fns';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import * as Yup from 'yup';
import { Project as GQLProject } from '../../../gql';
import ApolloErrorAlert from '../../errors/ApolloErrorAlert';
import ProjectAvatar from '../../projects/ProjectAvatar';
import { useEventFormStyles } from '../commons';

type Project = Pick<GQLProject, 'id' | 'name'>;

export interface TimeLogFormCardProps {
  initialValues?: Partial<TimeLogFormValue>;
  projects: Project[];
  disabled?: boolean;
  onSubmit: (values: TimeLogFormValue, bag: TimeLogFormBag) => void;
}

export interface TimeLogFormValue {
  label?: string;
  projectId?: number;
  startDate: Date;
  endDate: Date;
}

export type TimeLogFormBag = FormikHelpers<TimeLogFormValue>;

const TimeLogFormValueSchema = Yup.object().shape({
  label: Yup.string(),
});

const defaultValues: TimeLogFormValue = {
  label: '',
  startDate: new Date(),
  endDate: new Date(),
};

const TimeLogFormCard = ({
  initialValues,
  projects,
  disabled = false,
  onSubmit,
}: TimeLogFormCardProps) => {
  const [selectedProject, setSelectedProject] = React.useState<Project | null>(
    projects && initialValues?.projectId
      ? projects.find((p) => p.id === initialValues.projectId) || null
      : null,
  );
  const [endDateError, setEndDateError] = React.useState<string | undefined>(
    undefined,
  );
  const classes = useEventFormStyles();

  /** */
  const _onSubmit = (values: TimeLogFormValue, bag: TimeLogFormBag) => {
    setEndDateError(undefined);
    if (!isAfter(values.endDate, values.startDate)) {
      setEndDateError(
        'La date de fin doit être postérieure à la date de début',
      );
      bag.setSubmitting(false);
      return;
    }
    onSubmit(values, bag);
  };


  /** */
  return (
    <Formik
      initialValues={{ ...defaultValues, ...initialValues }}
      validationSchema={TimeLogFormValueSchema}
      onSubmit={_onSubmit}
    >
      {({ status, values, isSubmitting, setFieldValue }) => (
        <Form>
          <Card>
            {status && status.apolloError && (
              <CardContent>
                <ApolloErrorAlert error={status.apolloError} />
              </CardContent>
            )}

            <CardContent>
              <Field
                fullWidth
                margin="normal"
                variant="outlined"
                name="label"
                label="Label"
                disabled={disabled || isSubmitting}
                component={TextField}
              />

              <Autocomplete
                value={selectedProject}
                options={projects}
                getOptionLabel={(option) => `${option.name}`}
                getOptionSelected={(option, value) => option.id === value.id}
                renderOption={(option) => (
                  <>
                    <Box mr={2} component="span">
                      <ProjectAvatar size="small" project={option} />
                    </Box>
                    <span>{`${option.name}`}</span>
                  </>
                )}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    label="Selectionner un projet"
                    variant="outlined"
                    margin="normal"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: selectedProject ? (
                        <InputAdornment position="start">
                          <ProjectAvatar
                            size="small"
                            project={selectedProject}
                          ></ProjectAvatar>
                        </InputAdornment>
                      ) : undefined,
                    }}
                  />
                )}
                disabled={disabled || isSubmitting}
                onChange={(_, newValue) => {
                  setFieldValue('projectId', newValue?.id);
                  setSelectedProject(newValue);
                }}
              />

              <MuiDatePicker
                fullWidth
                margin="normal"
                inputVariant="outlined"
                format="EEEE d MMM yyyy"
                label="Date"
                value={values.startDate}
                disabled={disabled || isSubmitting}
                onChange={(newDate: any) => {
                  setFieldValue(
                    'startDate',
                    setMinutes(
                      setHours(newDate, getHours(values.startDate)),
                      getMinutes(values.startDate),
                    ),
                  );
                  setFieldValue(
                    'endDate',
                    setMinutes(
                      setHours(newDate, getHours(values.endDate)),
                      getMinutes(values.endDate),
                    ),
                  );
                }}
              />

              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box className={classes.staticTimePickerContainer}>
                      <MuiTimePicker
                        fullWidth
                        margin="normal"
                        variant="static"
                        orientation="landscape"
                        inputVariant="outlined"
                        format="HH'h'mm"
                        ampm={false}
                        label="Début"
                        value={values.startDate}
                        onChange={(newDate: any) => {
                          setFieldValue('startDate', newDate);
                        }} 
                      />
                      {(disabled || isSubmitting) && (
                        <Box className={classes.staticTimePickerOver} />
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className={classes.staticTimePickerContainer}>
                      <MuiTimePicker
                        fullWidth
                        margin="normal"
                        variant="static"
                        orientation="landscape"
                        inputVariant="outlined"
                        format="HH'h'mm"
                        ampm={false}
                        label="Fin"
                        value={values.endDate}
                        onChange={(newDate: any) => {
                          setFieldValue('endDate', newDate);
                        }}
                      />
                      {(disabled || isSubmitting) && (
                        <Box className={classes.staticTimePickerOver} />
                      )}
                    </Box>
                  </Grid>
                </Grid>
                {endDateError && (
                  <Typography style={{ color: '#f44336' }} align="center">
                    {endDateError}
                  </Typography>
                )}
              </Box>

             
            </CardContent>

            {!disabled && (
              <CardContent>
                <Box display="flex" justifyContent="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Valider
                  </Button>
                </Box>
              </CardContent>
            )}

            {isSubmitting && <LinearProgress />}
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default TimeLogFormCard;
