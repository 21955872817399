import { Box, LinearProgress, makeStyles } from '@material-ui/core';
import React from 'react';

export interface StaticLinearProgressProps {}

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
  },
}));

const StaticLinearProgress = (props: StaticLinearProgressProps) => {
  const classes = useStyles();
  /** */
  return (
    <Box className={classes.container}>
      <LinearProgress />
    </Box>
  );
};

export default StaticLinearProgress;
