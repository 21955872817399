import { makeStyles } from '@material-ui/core';

export const useEventFormStyles = makeStyles((theme) => ({
  staticTimePickerContainer: {
    position: 'relative',
    padding: theme.spacing(1),
    border: '1px solid #ffffff3b',
    borderRadius: 4,
  },
  staticTimePickerOver: {
    position: 'absolute',
    zIndex: 999,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.background.paper,
    opacity: 0.7,
  },
}));
