import { GraphQLError } from 'graphql';
import React from 'react';
import AlertError from './AlertError';

export interface ApolloGqlErrorAlertProps {
  graphqlError: GraphQLError;
}

const ApolloGqlErrorAlert = ({ graphqlError }: ApolloGqlErrorAlertProps) => {
  return (
    <AlertError
      message={(graphqlError.extensions?.code) as any}
      details={graphqlError.message}
    />
  );
};

export default ApolloGqlErrorAlert;
