var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { fragmentMapFrom, replaceFragmentsOn } from "./fragment-utils";
import { isFragmentDefinitionNode, isOperationDefinitionNode } from "./node-types";
export function fragmentReducer(doc) {
    if (!doc || !doc.definitions || !doc.definitions.length)
        return null;
    const operationNode = doc.definitions.find(isOperationDefinitionNode) || null;
    if (!operationNode)
        return null;
    const fragments = doc.definitions.filter(isFragmentDefinitionNode);
    const fragmentMap = fragmentMapFrom(fragments);
    const _a = operationNode.selectionSet, { selections } = _a, selectionSet = __rest(_a, ["selections"]);
    const list = replaceFragmentsOn(selections, fragmentMap);
    return Object.assign(Object.assign({}, operationNode), { selectionSet: Object.assign(Object.assign({}, selectionSet), { selections: list }) });
}
