import React from 'react';
import { ApolloError } from '@apollo/client';
import ApolloNetworkErrorAlert, {
  ApolloNetworkErrorAlertProps,
} from './ApolloNetworkErrorAlert';
import ApolloGqlErrorAlert, {
  ApolloGqlErrorAlertProps,
} from './ApolloGqlErrorAlert';

export interface ApolloErrorAlertProps {
  error: ApolloError;
  NetworkErrorAlertComponent?: React.ComponentType<ApolloNetworkErrorAlertProps>;
  GqlErrorAlertComponent?: React.ComponentType<ApolloGqlErrorAlertProps>;
}
const ApolloErrorAlert = ({
  error: { networkError, graphQLErrors },
  NetworkErrorAlertComponent,
  GqlErrorAlertComponent,
}: ApolloErrorAlertProps) => {
  if (networkError) {
    if (NetworkErrorAlertComponent)
      return <NetworkErrorAlertComponent networkError={networkError} />;
    return <ApolloNetworkErrorAlert networkError={networkError} />;
  }

  if (graphQLErrors && graphQLErrors.length > 0) {
    return (
      <>
        {graphQLErrors
          .map((graphQLError, index) => ({ id: index, graphQLError }))
          .map(({ id, graphQLError }) =>
            GqlErrorAlertComponent ? (
              <GqlErrorAlertComponent key={id} graphqlError={graphQLError as any} />
            ) : (
              <ApolloGqlErrorAlert key={id} graphqlError={graphQLError as any} />
            ),
          )}
      </>
    );
  }

  throw new Error('ApolloErrorAlert');
};

export default ApolloErrorAlert;
