import { Box, Container, LinearProgress, Typography } from '@material-ui/core';
import React from 'react';
import StaticLinearProgress from '../utils/StaticLinearProgress';
import Nav from './Nav';

export interface DefaultLayoutProps {
  children?: React.ReactNode;
  loading?: Boolean;
  loadingMore?: Boolean;
  title?: React.ReactNode;
  withoutContainer?: boolean;
}

const DefaultLayout = ({
  children,
  loading = false,
  loadingMore = false,
  title,
  withoutContainer = false,
}: DefaultLayoutProps) => {
  /** */
  const renderTitle = () => {
    if (!title) return null;
    return (
      <>
        {typeof title === 'string' ? (
          <Typography variant="h2">{title}</Typography>
        ) : (
          title
        )}
      </>
    );
  };

  const renderBody = () => {
    if (loading) return <LinearProgress />;
    if (withoutContainer) {
      return (
        <>
          {loadingMore && <StaticLinearProgress />}
          {renderTitle()}
          <Box>{children}</Box>
        </>
      );
    }
    return (
      <Container maxWidth="md">
        {loadingMore && <StaticLinearProgress />}
        {renderTitle()}
        <Box my={4}>{children}</Box>
      </Container>
    );
  };

  /** */
  return (
    <Box position="relative" height="100vh">
      <Box py={withoutContainer ? 0 : 2} ml="80px">
        {renderBody()}
      </Box>
      <Box position="fixed" top={0} left={0} bottom={0}>
        <Nav />
      </Box>
    </Box>
  );
};

export default DefaultLayout;
