import { Navigate } from 'react-router-dom';
import { UserRole } from '../gql';
import { useAuth } from './auth-context';

export const RequireAuth = ({
  children,
  role,
}: {
  children: JSX.Element;
  role?: UserRole;
}) => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (role && user.role !== role) {
    return <Navigate to="/" />;
  }

  return children;
};
