import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  InputAdornment,
} from '@material-ui/core';
import deepMerge from 'deepmerge';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import { DatePicker } from 'formik-material-ui-pickers';
import React from 'react';
import * as Yup from 'yup';
import { DeepPartial } from '../../interfaces';
import ApolloErrorAlert from '../errors/ApolloErrorAlert';

export interface UserFormCardProps {
  initialValues?: DeepPartial<UserFormValues>;
  onSubmit: (values: UserFormValues, formikHelpers: UserFormBag) => void;
}

export interface UserFormValues {
  firstName: string;
  lastName: string;
  birthDate: Date;
  email: string;
  password: string;
  password2: string;
  contract: {
    startDate: Date;
    sunday: number;
    monday: number;
    tuesday: number;
    wednesday: number;
    thursday: number;
    friday: number;
    saturday: number;
  };
}

export type UserFormBag = FormikHelpers<UserFormValues>;

const UserFormValuesSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  birthDate: Yup.date().required(),
  email: Yup.string().email('Adresse email invalie').required('Required'),
  password: Yup.string().required('Required'),
  password2: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Les mots de passe doivent correspondre',
  ),
  contract: Yup.object().shape({
    startDate: Yup.date().required(),
    sunday: Yup.number().min(0).max(660).required(),
    monday: Yup.number().min(0).max(660).required(),
    tuesday: Yup.number().min(0).max(660).required(),
    wednesday: Yup.number().min(0).max(660).required(),
    thursday: Yup.number().min(0).max(660).required(),
    friday: Yup.number().min(0).max(660).required(),
    saturday: Yup.number().min(0).max(660).required(),
  }),
});

const defaultInitialValues: UserFormValues = {
  firstName: '',
  lastName: '',
  birthDate: new Date(),
  email: '',
  password: '',
  password2: '',
  contract: {
    startDate: new Date(),
    sunday: 0,
    monday: 420,
    tuesday: 420,
    wednesday: 420,
    thursday: 420,
    friday: 0,
    saturday: 0,
  },
};

const UserFormCard = ({
  initialValues = defaultInitialValues,
  onSubmit,
}: UserFormCardProps) => {
  /** */
  const computeWeeklyWorkingTime = ({
    contract: {
      sunday,
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
    },
  }: UserFormValues) => {
    return (
      (sunday + monday + tuesday + wednesday + thursday + friday + saturday) /
      60
    );
  };

  /** */
  return (
    <Formik
      initialValues={
        deepMerge(defaultInitialValues, initialValues) as UserFormValues
      }
      validationSchema={UserFormValuesSchema}
      onSubmit={onSubmit}
    >
      {({ status, isSubmitting, values }) => (
        <Form>
          <Card>
            {status && status.apolloError && (
              <CardContent>
                <ApolloErrorAlert error={status.apolloError} />
              </CardContent>
            )}

            <CardHeader title="Infos"></CardHeader>
            <CardContent>
              <Field
                fullWidth
                // margin="normal"
                variant="outlined"
                name="firstName"
                label="Prénom"
                component={TextField}
              />
              <Field
                fullWidth
                margin="normal"
                variant="outlined"
                name="lastName"
                label="Nom"
                component={TextField}
              />

              <Field
                fullWidth
                margin="normal"
                inputVariant="outlined"
                name="birthDate"
                label="Date de naissance"
                format="d MMM yyyy"
                openTo="year"
                component={DatePicker}
              />
            </CardContent>

            <CardHeader title="Accès"></CardHeader>
            <CardContent>
              <Field
                fullWidth
                // margin="normal"
                variant="outlined"
                name="email"
                type="email"
                label="Email"
                component={TextField}
              />

              <Field
                fullWidth
                margin="normal"
                variant="outlined"
                name="password"
                type="password"
                label="Mot de passe"
                component={TextField}
              />
              <Field
                fullWidth
                margin="normal"
                variant="outlined"
                name="password2"
                type="password"
                label="Répéter le mot de passe"
                component={TextField}
              />
            </CardContent>

            <CardHeader
              title={`Contrat : ${computeWeeklyWorkingTime(
                values,
              )} h / semaine`}
            ></CardHeader>
            <CardContent>
              <Field
                fullWidth
                margin="normal"
                inputVariant="outlined"
                name="contract.startDate"
                label="Date de début du contract"
                format="d MMM yyyy"
                openTo="year"
                component={DatePicker}
              />
              <Box display="flex">
                {[
                  { name: 'sunday', label: 'Dimanche' },
                  { name: 'monday', label: 'Lundi' },
                  { name: 'tuesday', label: 'Mardi' },
                  { name: 'wednesday', label: 'Mercredi' },
                  { name: 'thursday', label: 'Jeudi' },
                  { name: 'friday', label: 'Vendredi' },
                  { name: 'saturday', label: 'Samedi' },
                ].map((d) => (
                  <Field
                    key={d.name}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    name={`contract.${d.name}`}
                    type="number"
                    label={d.label}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">mn</InputAdornment>
                      ),
                    }}
                    component={TextField}
                  />
                ))}
              </Box>
            </CardContent>

            <CardContent>
              <Box p={2} display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Valider
                </Button>
              </Box>
            </CardContent>

            {isSubmitting && <LinearProgress />}
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default UserFormCard;
