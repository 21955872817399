import ColorHash from 'color-hash';

const colorHasher = new ColorHash();

export const getUserColor = ({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName: string;
}) => {
  return colorHasher.hex(`user ${firstName} ${lastName}`);
};

export const getProjectColor = ({ name }: { name: string }) => {
  return colorHasher.hex(`project ${name}`);
};
