import { getNullableType, isEnumType, isListType, isNonNullType, isScalarType, } from "graphql";
import has from "lodash.has";
import mapValues from "lodash.mapvalues";
import omit from "lodash.omit";
import { isNone } from "./is-none";
import { mapIfArray } from "./map-if-array";
export class Serializer {
    constructor(schema, functionsMap, removeTypenameFromInputs, nullFunctions) {
        this.schema = schema;
        this.functionsMap = functionsMap;
        this.removeTypenameFromInputs = removeTypenameFromInputs;
        this.nullFunctions = nullFunctions;
    }
    serialize(value, type) {
        if (isNonNullType(type)) {
            return this.serializeInternal(value, getNullableType(type));
        }
        else {
            return this.serializeNullable(value, getNullableType(type));
        }
    }
    serializeNullable(value, type) {
        return this.nullFunctions.serialize(this.serializeInternal(value, type));
    }
    serializeInternal(value, type) {
        if (isNone(value)) {
            return value;
        }
        if (isScalarType(type) || isEnumType(type)) {
            return this.serializeLeaf(value, type);
        }
        if (isListType(type)) {
            return mapIfArray(value, (v) => this.serialize(v, type.ofType));
        }
        return this.serializeInputObject(value, type);
    }
    serializeLeaf(value, type) {
        const fns = this.functionsMap[type.name] || type;
        return fns.serialize(value);
    }
    serializeInputObject(givenValue, type) {
        const value = this.removeTypenameFromInputs && has(givenValue, "__typename") ? omit(givenValue, "__typename") : givenValue;
        const fields = type.getFields();
        return mapValues(value, (v, key) => {
            const f = fields[key];
            return f ? this.serialize(v, f.type) : v;
        });
    }
}
