import { gql } from '@apollo/client';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import DefaultLayout from '../components/layout/DefaultLayout';
import ProjectFormCard, {
  ProjectFormBag,
  ProjectFormValues,
} from '../components/projects/ProjectFormCard';
import { useCreateProjectMutation, useUsersQuery } from '../gql';

export interface CreateProjectPageProps {}

const CreateProjectPage = (props: CreateProjectPageProps) => {
  const { data: usersData, loading: usersLoading } = useUsersQuery();
  const [createProject] = useCreateProjectMutation();
  const navigate = useNavigate();
  /** */
  const users = usersData && usersData.users;

  /** */
  const onSubmit = async (values: ProjectFormValues, bag: ProjectFormBag) => {
    try {
      const { data } = await createProject({
        variables: { input: values },
        update: (cache, { data }) => {
          const createdProject = data && data.createProject;

          cache.modify({
            fields: {
              projects(existingProjects = []) {
                const newCacheRef = cache.writeFragment({
                  data: createdProject,
                  fragment: gql`
                    fragment NewProject on Project {
                      id
                      type
                    }
                  `,
                });
                return [...existingProjects, newCacheRef];
              },
            },
          });
        },
      });
      navigate(`/projects/${data!.createProject!.id}`);
    } catch (error) {
      bag.setStatus({ apolloError: error });
      bag.setSubmitting(false);
    }
  };

  /** */
  return (
    <DefaultLayout title="Création d'un projet" loading={usersLoading}>
      <ProjectFormCard users={users || []} onSubmit={onSubmit} />
    </DefaultLayout>
  );
};

export default CreateProjectPage;
