import {
  Box,
  Divider,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ClassIcon from '@material-ui/icons/Class';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../auth/auth-context';
import { useMe } from '../../auth/me-context';
import { getUserColor } from '../../utils/colors';
import { userAvailableRecoveryTime } from '../../utils/parser';
import Chrono from '../events/time-logs/Chrono';
import SosDialog from './SosDialog';

const useStyles = makeStyles(() => ({
  text: {
    textAlign: 'center',
    fontSize: 9,
  },
}));

const SosIcon = () => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24">
      <path
        fill="red"
        d="M11,15H13V17H11V15M11,7H13V13H11V7M12,3A9,9 0 0,0 3,12A9,9 0 0,0 12,21A9,9 0 0,0 21,12A9,9 0 0,0 12,3M12,19C8.14,19 5,15.86 5,12C5,8.14 8.14,5 12,5C15.86,5 19,8.14 19,12C19,15.86 15.86,19 12,19M20.5,20.5C22.66,18.31 24,15.31 24,12C24,8.69 22.66,5.69 20.5,3.5L19.42,4.58C21.32,6.5 22.5,9.11 22.5,12C22.5,14.9 21.32,17.5 19.42,19.42L20.5,20.5M4.58,19.42C2.68,17.5 1.5,14.9 1.5,12C1.5,9.11 2.68,6.5 4.58,4.58L3.5,3.5C1.34,5.69 0,8.69 0,12C0,15.31 1.34,18.31 3.5,20.5L4.58,19.42Z"
      />
    </svg>
  );
};

const Nav = () => {
  const { logout } = useAuth();
  const me = useMe();
  const classes = useStyles();
  const [sosIsOpen, toggleSos] = React.useState(false);

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      bgcolor="#1e3742"
    >
      <Box py={2} px={2}>
        <Link to="/">
          <IconButton>
            <AccessAlarmIcon />
          </IconButton>
        </Link>
      </Box>

      <Box my={1} px={2}>
        <Divider />
      </Box>

      <Chrono />

      <Box my={1} px={2}>
        <Divider />
      </Box>

      <Box py={2} px={2} flex={1} display="flex" flexDirection="column">
        <Link to="/projects">
          <IconButton>
            <ClassIcon />
          </IconButton>
        </Link>

        <Link to="/users">
          <IconButton>
            <PeopleAltIcon />
          </IconButton>
        </Link>
      </Box>

      <Box my={1} px={2}>
        <Divider />
      </Box>

      <Box py={2} px={2} display="flex" justifyContent="center">
        <IconButton
          onClick={() => {
            toggleSos(true);
          }}
        >
          <SosIcon />
        </IconButton>

        <SosDialog
          open={sosIsOpen}
          onClose={() => {
            toggleSos(false);
          }}
        />
      </Box>

      <Box my={1} px={2}>
        <Divider />
      </Box>

      <Box py={2} px={2} display="flex" flexDirection="column">
        <IconButton>
          <TimelapseIcon style={{ color: getUserColor(me) }} />
        </IconButton>
        <Box mb={1}>
          <Typography className={classes.text}>
            {`~ ${Math.round(userAvailableRecoveryTime(me) / 3600)} heures`}
          </Typography>
        </Box>

        {/* <IconButton>
          <Brightness5Icon style={{ color: getUserColor(me) }} />
        </IconButton>
        <Box mb={1}>
          <Typography className={classes.text}>
            {`~ ${Math.round(userVacationBalance(me))} jours`}
          </Typography>
        </Box> */}

        <Link to={`/users/${me.id}`}>
          <IconButton style={{ color: getUserColor(me) }}>
            <AccountCircleIcon />
          </IconButton>
        </Link>

        <IconButton onClick={logout}>
          <ExitToAppIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Nav;
