import { Avatar, makeStyles } from '@material-ui/core';
import React from 'react';
import { User as GQLUser } from '../../gql';
import { getUserColor } from '../../utils/colors';

type User = Pick<GQLUser, 'firstName' | 'lastName'>;

type AvatarSize = 'default' | 'small' | 'big';

export interface UserAvatarProps {
  user: User;
  size?: AvatarSize;
}

const useStyles = makeStyles((theme) => ({
  avatar: ({ color, size }: { color: string; size: AvatarSize }) => ({
    background: color,
    color: theme.palette.getContrastText(color),

    ...(size === 'big'
      ? {
          width: theme.spacing(7),
          height: theme.spacing(7),
        }
      : {}),
    ...(size === 'small'
      ? {
          width: theme.spacing(3),
          height: theme.spacing(3),
          fontSize: theme.typography.overline.fontSize,
        }
      : {}),
  }),
}));

const UserAvatar = ({ user, size = 'default' }: UserAvatarProps) => {
  const classes = useStyles({
    color: getUserColor(user),
    size,
  });

  return (
    <Avatar className={classes.avatar}>{`${user.firstName.charAt(
      0,
    )}${user.lastName.charAt(0)}`}</Avatar>
  );
};

export default UserAvatar;
