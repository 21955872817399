import React from 'react';
import { Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

export interface AlertErrorProps {
  message: string;
  details?: string;
}

const AlertError = ({ message, details }: AlertErrorProps) => {
  return (
    <Alert severity="error">
      {details && <AlertTitle>{message}</AlertTitle>}
      <Typography>{details || message}</Typography>
    </Alert>
  );
};

export default AlertError;
