import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { withScalars } from 'apollo-link-scalars';
import { buildClientSchema, IntrospectionQuery } from 'graphql';
import { JWT_LOCAL_STORAGE_KEY } from './constants';
import introspection from './gql/introspection.json';

const typesMap = {
  DateTime: {
    serialize: (parsed: Date) => parsed.toISOString(),
    parseValue: (raw: string | number | null): Date | null => {
      return raw ? new Date(raw) : null;
    },
  },
};

export const createApolloClient = () => {
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHL_URL,
  });

  const schema = buildClientSchema(
    introspection as unknown as IntrospectionQuery,
  );

  const withScalarsLink = ApolloLink.from([
    withScalars({ schema, typesMap: typesMap as any }),
    httpLink,
  ]);

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem(JWT_LOCAL_STORAGE_KEY);
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  return new ApolloClient({
    link: authLink.concat(withScalarsLink),
    cache: new InMemoryCache(),
  });
};
