import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  LinearProgress,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../auth/auth-context';

interface LoginFormValues {
  email: string;
  password: string;
}

const LoginPage = () => {
  const { user, login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  /** */
  const onSubmit = (values: LoginFormValues) => {
    login(values.email, values.password);
  };

  /** */
  React.useEffect(() => {
    if (user) {
      const { from }: any = location.state || { from: { pathname: '/' } };
      navigate(from);
    }
  }, [user, navigate, location]);

  /** */
  return (
    <Box
      px={2}
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Container maxWidth="sm">
        <Card>
          <Formik
            initialValues={{ email: '', password: '' }}
            onSubmit={onSubmit}
          >
            {({ submitForm, isSubmitting }) => (
              <Form>
                <CardContent>
                  <Field
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    name="email"
                    type="email"
                    label="Email"
                    component={TextField}
                  />
                  <Field
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    name="password"
                    type="password"
                    label="Mot de passe"
                    component={TextField}
                  />
                </CardContent>
                <Box p={2} mb={2} display="flex" justifyContent="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    // onClick={submitForm}
                  >
                    Valider
                  </Button>
                </Box>
                {isSubmitting && <LinearProgress />}
              </Form>
            )}
          </Formik>
        </Card>
      </Container>
    </Box>
  );
};

export default LoginPage;
