import { ApolloProvider } from '@apollo/client';
import DateFnsUtils from '@date-io/date-fns';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import format from 'date-fns/format';
import frLocale from 'date-fns/locale/fr';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createApolloClient } from './apollo-client';
import { AuthProvider } from './auth/auth-context';
import { RequireAuth } from './auth/PrivateRoute';
import { ChronoProvider } from './chrono/chrono-context';
import { UserRole } from './gql';
import CreateProjectPage from './pages/CreateProjectPage';
import CreateUserPage from './pages/CreateUserPage';
import DashboardPage from './pages/DashboardPage';
import EditProjectPage from './pages/EditProjectPage';
import LoginPage from './pages/LoginPage';
import ProjectPage from './pages/ProjectPage';
import ProjectsPage from './pages/ProjectsPage';
import UserPage from './pages/UserPage';
import UsersPage from './pages/UsersPage';
import theme from './theme';

const client = createApolloClient();

class FrLocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date: Date) {
    return format(date, 'd MMM yyyy', { locale: this.locale });
  }
}

function App() {
  return (
    <ApolloProvider client={client}>
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={FrLocalizedUtils} locale={frLocale}>
          <CssBaseline />

          <AuthProvider>
            <Router>
              <Routes>
                <Route path="/login" element={<LoginPage />}></Route>

                <Route
                  path="/*"
                  element={
                    <RequireAuth>
                      <ChronoProvider>
                        <Routes>
                          <Route
                            path="create/project"
                            element={
                              <RequireAuth role={UserRole.Admin}>
                                <CreateProjectPage />
                              </RequireAuth>
                            }
                          />
                          <Route
                            path="create/user"
                            element={
                              <RequireAuth role={UserRole.Admin}>
                                <CreateUserPage />
                              </RequireAuth>
                            }
                          />

                          <Route path="projects" element={<ProjectsPage />} />
                          <Route
                            path="projects/:id"
                            element={<ProjectPage />}
                          />
                          <Route
                            path="projects/:id/edit"
                            element={<EditProjectPage />}
                          />

                          <Route path="users" element={<UsersPage />} />
                          <Route path="users/:id" element={<UserPage />} />

                          <Route index element={<DashboardPage />} />
                        </Routes>
                      </ChronoProvider>
                    </RequireAuth>
                  }
                />
              </Routes>
            </Router>
          </AuthProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </ApolloProvider>
  );
}

export default App;
