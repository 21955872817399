import { Box, useTheme } from '@material-ui/core';
import { addWeeks, startOfMonth } from 'date-fns';
import { addMinutes, endOfMonth, startOfDay, subWeeks } from 'date-fns/esm';
import React from 'react';
import { Calendar, View } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useMe } from '../auth/me-context';
import { useCalendatStyles } from '../components/calendar/calendar-styles';
import CalendarNav from '../components/calendar/CalendarNav';
import DefaultLayout from '../components/layout/DefaultLayout';
import CreateEventDialogContainer from '../containers/CreateEventDialogContainer';
import EditEventDialogContainer from '../containers/EditEventDialogContainer';
import {
  TimeOffType,
  useHolidaysQuery,
  useTimeLogsQuery,
  useTimeOffsQuery,
} from '../gql';
import { CalendarEvent, CalendarUserEvent, SlotInfo } from '../interfaces';
import { getUserColor } from '../utils/colors';
import { calendarDateFnsLocalizer } from '../utils/date';
import {
  holidayToCalendarEvent,
  timeLogToCalendarEvent,
  timeOffToCalendarEvent,
} from '../utils/parser';

const DashboardPage = () => {
  const me = useMe();
  const [date, setDate] = React.useState(new Date());
  const startDate = subWeeks(startOfMonth(date), 1);
  const endDate = addWeeks(endOfMonth(date), 1);
  const {
    data: holidaysData,
    loading: holidaysLoading,
    error: holidaysError,
  } = useHolidaysQuery({
    variables: {
      input: {
        endDateBefore: startDate,
        startDateAfter: endDate,
      },
    },
  });
  const {
    data: tfData,
    loading: tfLoading,
    error: tfError,
  } = useTimeOffsQuery({
    variables: {
      input: {
        endDateBefore: startDate,
        startDateAfter: endDate,
      },
    },
  });
  const {
    data: tlsData,
    loading: tlsLoading,
    error: tlsError,
  } = useTimeLogsQuery({
    variables: {
      input: {
        userId: me.id,
        endDateBefore: startDate,
        startDateAfter: endDate,
      },
    },
  });
  /** */
  const [currentUserEvent, setCurrentUserEvent] = React.useState<
    CalendarUserEvent | undefined
  >();
  const [currentSlotInfo, setCurrentSlotInfo] = React.useState<
    SlotInfo | undefined
  >();
  const [view, setView] = React.useState<View>('month');
  const theme = useTheme();
  const classes = useCalendatStyles();
  /** */
  const loading = holidaysLoading || tfLoading || tlsLoading;
  // TODO
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const error = holidaysError || tfError || tlsError;
  const holidays = (holidaysData && holidaysData.holidays) || [];
  const tf = (tfData && tfData.timeOff) || [];
  const tls = (tlsData && tlsData.timeLogs) || [];
  const events: CalendarEvent[] = [
    ...holidays.map(holidayToCalendarEvent),
    ...tf.map(timeOffToCalendarEvent),
    ...tls.map(timeLogToCalendarEvent),
  ];

  /** */
  const renderEventStyle = (event: CalendarEvent) => {
    if (event.resource.calendarEventType === 'timeoff') {
      return { className: classes.timeOffEvent };
    }
    if (event.resource.calendarEventType === 'timelog') {
      const userColor = getUserColor(me);
      const contrastText = theme.palette.getContrastText(userColor);
      return {
        style: {
          color: contrastText,
          backgroundColor: userColor,
          // backgroundImage: genCssBackgroundImagePattern('tinyCheckers', {
          //   color: Color(projectColor).lighten(1).hex(),
          //   opacity: 0.5,
          // }),
        },
      };
    }
    if (event.resource.calendarEventType === 'holiday') {
      return { className: classes.holidayEvent };
    }
    return { className: '' };
  };

  /** */
  const onView = (newView: View) => {
    if (newView !== 'month') return;
    setView(newView);
  };

  const onNavigate = (newDate: Date) => {
    setDate(newDate);
  };

  const onSelectEvent = (event: CalendarEvent) => {
    if (
      event.resource.calendarEventType === 'timeoff' ||
      event.resource.calendarEventType === 'timelog'
    ) {
      setCurrentUserEvent(event as CalendarUserEvent);
    }
  };

  const onEditEventDialogClose = () => {
    setCurrentUserEvent(undefined);
  };

  const onCreateEventDialogClose = () => {
    setCurrentSlotInfo(undefined);
  };

  const startAccessor = (event: CalendarEvent) => {
    if (view !== 'month') {
      return event.resource.startDate;
    }

    if (event.resource.calendarEventType === 'holiday') {
      return addMinutes(startOfDay(event.resource.startDate), 1);
    } else if (event.resource.calendarEventType === 'timeoff') {
      if (event.resource.type === TimeOffType.Vacation) {
        return addMinutes(startOfDay(event.resource.startDate), 10);
      } else if (event.resource.type === TimeOffType.WithoutPay) {
        return addMinutes(startOfDay(event.resource.startDate), 20);
      } else if (event.resource.type === TimeOffType.Recovery) {
        return addMinutes(startOfDay(event.resource.startDate), 30);
      }
    } else if (event.resource.calendarEventType === 'timelog') {
      return addMinutes(startOfDay(event.resource.startDate), 50);
    }
    return addMinutes(startOfDay(event.resource.startDate), 100);
  };

  /** */
  return (
    <DefaultLayout withoutContainer loadingMore={loading}>
      <Box height="100vh" display="flex" flexDirection="column">
        <CalendarNav date={date} onNavigate={onNavigate} />

        <Box className={classes.calendarContainer} flex={1} px={4} pb={4}>
          <Calendar
            selectable
            popup
            culture="fr"
            view={view}
            views={['month']}
            date={date}
            localizer={calendarDateFnsLocalizer}
            events={events}
            toolbar={false}
            startAccessor={startAccessor}
            eventPropGetter={renderEventStyle}
            onView={onView}
            onNavigate={onNavigate}
            onSelectEvent={onSelectEvent}
            onSelectSlot={setCurrentSlotInfo}
          />
        </Box>
      </Box>

      <EditEventDialogContainer
        event={currentUserEvent}
        onClose={onEditEventDialogClose}
      />

      <CreateEventDialogContainer
        slotInfo={currentSlotInfo}
        onClose={onCreateEventDialogClose}
      />
    </DefaultLayout>
  );
};

export default DashboardPage;
