import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
} from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import * as Yup from 'yup';
import { User as GQLUser } from '../../gql';
import ApolloErrorAlert from '../errors/ApolloErrorAlert';
import UsersSelector from '../users/UsersSelector';

type User = Pick<GQLUser, 'id' | 'lastName' | 'firstName'>;

export interface ProjectFormCardProps {
  users: User[];
  initialValues?: Partial<ProjectFormValues>;
  onSubmit: (values: ProjectFormValues, formikHelpers: ProjectFormBag) => void;
}

export interface ProjectFormValues {
  name: string;
  pitch?: string;
  userIds: number[];
}

export type ProjectFormBag = FormikHelpers<ProjectFormValues>;

const ProjectFormValuesSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
});

const defaultInitialValues: ProjectFormValues = {
  name: '',
  pitch: '',
  userIds: [],
};

const ProjectFormCard = ({
  users,
  initialValues = defaultInitialValues,
  onSubmit,
}: ProjectFormCardProps) => {
  /** */
  return (
    <Formik
      initialValues={{ ...defaultInitialValues, ...initialValues }}
      validationSchema={ProjectFormValuesSchema}
      onSubmit={onSubmit}
    >
      {({ status, isSubmitting, setFieldValue }) => (
        <Form>
          <Card>
            {status && status.apolloError && (
              <CardContent>
                <ApolloErrorAlert error={status.apolloError} />
              </CardContent>
            )}

            <CardHeader title="Infos"></CardHeader>
            <CardContent>
              <Field
                fullWidth
                // margin="normal"
                variant="outlined"
                name="name"
                label="Nom"
                component={TextField}
              />
              <Field
                fullWidth
                margin="normal"
                variant="outlined"
                name="pitch"
                label="Pitch"
                component={TextField}
              />
            </CardContent>

            {users && (
              <>
                <CardHeader title="Colègues"></CardHeader>
                <CardContent>
                  <UsersSelector
                    users={users}
                    initialValues={initialValues.userIds}
                    disabled={isSubmitting}
                    onChange={(us) => {
                      setFieldValue(
                        'userIds',
                        us.map((u) => u.id),
                      );
                    }}
                  />
                </CardContent>
              </>
            )}

            <CardContent>
              <Box display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Valider
                </Button>
              </Box>
            </CardContent>

            {isSubmitting && <LinearProgress />}
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default ProjectFormCard;
