import { gql } from '@apollo/client';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import DefaultLayout from '../components/layout/DefaultLayout';
import UserFormCard, {
  UserFormBag,
  UserFormValues,
} from '../components/users/UserFormCard';
import { useCreateUserMutation } from '../gql';

export interface CreateUserPageProps {}

const CreateUserPage = (props: CreateUserPageProps) => {
  const [createUser] = useCreateUserMutation();
  const navigate = useNavigate();

  /** */
  const onSubmit = async (
    { password2, ...values }: UserFormValues,
    bag: UserFormBag,
  ) => {
    try {
      const { data } = await createUser({
        variables: { input: values },
        update: (cache, { data }) => {
          const createdUser = data && data.createUser;

          cache.modify({
            fields: {
              users(existingUsers = []) {
                const newCacheRef = cache.writeFragment({
                  data: createdUser,
                  fragment: gql`
                    fragment NewUser on User {
                      id
                      type
                    }
                  `,
                });
                return [...existingUsers, newCacheRef];
              },
            },
          });
        },
      });
      navigate(`/users/${data!.createUser!.id}`);
    } catch (error) {
      bag.setStatus({ apolloError: error });
      bag.setSubmitting(false);
    }
  };

  /** */
  return (
    <DefaultLayout title="Ajout d'un collègue">
      <UserFormCard onSubmit={onSubmit} />
    </DefaultLayout>
  );
};

export default CreateUserPage;
