import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import {
  DatePicker as MuiDatePicker,
  TimePicker as MuiTimePicker,
} from '@material-ui/pickers';
import { isAfter } from 'date-fns';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { TimeOffType } from '../../../gql';
import ApolloErrorAlert from '../../errors/ApolloErrorAlert';
import { useEventFormStyles } from '../commons';

export interface RecoveryFormCardProps {
  initialValues?: Partial<RecoveryFormValue>;
  disabled?: boolean;
  onSubmit: (values: RecoveryFormValue, bag: RecoveryFormBag) => void;
}

export interface RecoveryFormValue {
  startDate: Date;
  endDate: Date;
  type: TimeOffType.Recovery;
}

export type RecoveryFormBag = FormikHelpers<RecoveryFormValue>;

const defaultValues: RecoveryFormValue = {
  startDate: new Date(),
  endDate: new Date(),
  type: TimeOffType.Recovery,
};

const RecoveryFormCard = ({
  initialValues,
  disabled = false,
  onSubmit,
}: RecoveryFormCardProps) => {
  const [endDateError, setEndDateError] = React.useState<string | undefined>(
    undefined,
  );
  const classes = useEventFormStyles();

  /** */
  const _onSubmit = (values: RecoveryFormValue, bag: RecoveryFormBag) => {
    setEndDateError(undefined);
    if (!isAfter(values.endDate, values.startDate)) {
      setEndDateError(
        'La date de fin doit être postérieure à la date de début',
      );
      bag.setSubmitting(false);
      return;
    }
    onSubmit(values, bag);
  };

  /** */
  return (
    <Formik
      initialValues={{ ...defaultValues, ...initialValues }}
      onSubmit={_onSubmit}
    >
      {({ status, values, isSubmitting, setFieldValue }) => (
        <Form>
          <Card>
            {status && status.apolloError && (
              <CardContent>
                <ApolloErrorAlert error={status.apolloError} />
              </CardContent>
            )}

            <CardContent>
              <MuiDatePicker
                fullWidth
                margin="normal"
                inputVariant="outlined"
                format="EEEE d MMM yyyy"
                label="Date"
                value={values.startDate}
                disabled={disabled || isSubmitting}
                onChange={(newDate: any) => {
                  setFieldValue('startDate', newDate);
                  setFieldValue('endDate', newDate);
                }}
              />

              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box className={classes.staticTimePickerContainer}>
                      <MuiTimePicker
                        fullWidth
                        margin="normal"
                        variant="static"
                        orientation="landscape"
                        inputVariant="outlined"
                        format="HH'h'mm"
                        ampm={false}
                        label="Début"
                        value={values.startDate}
                        disabled={disabled || isSubmitting}
                        onChange={(newDate: any) => {
                          setFieldValue('startDate', newDate);
                        }}
                      />
                      {(disabled || isSubmitting) && (
                        <Box className={classes.staticTimePickerOver} />
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className={classes.staticTimePickerContainer}>
                      <MuiTimePicker
                        fullWidth
                        margin="normal"
                        variant="static"
                        orientation="landscape"
                        inputVariant="outlined"
                        format="HH'h'mm"
                        ampm={false}
                        label="Fin"
                        value={values.endDate}
                        disabled={disabled || isSubmitting}
                        onChange={(newDate: any) => {
                          setFieldValue('endDate', newDate);
                        }}
                      />
                      {(disabled || isSubmitting) && (
                        <Box className={classes.staticTimePickerOver} />
                      )}
                    </Box>
                  </Grid>
                </Grid>
                {endDateError && (
                  <Typography style={{ color: '#f44336' }} align="center">
                    {endDateError}
                  </Typography>
                )}
              </Box>
            </CardContent>

            {!disabled && (
              <CardContent>
                <Box display="flex" justifyContent="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Valider
                  </Button>
                </Box>
              </CardContent>
            )}

            {isSubmitting && <LinearProgress />}
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default RecoveryFormCard;
