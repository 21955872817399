import { GraphQLError, isEnumType, isInputObjectType, isListType, isNonNullType, isObjectType, isScalarType, } from "graphql";
import reduce from "lodash.reduce";
import { isNone } from "./is-none";
function ensureNullable(type) {
    return isNonNullType(type) ? type.ofType : type;
}
export class Parser {
    constructor(schema, functionsMap, validateEnums, nullFunctions) {
        this.schema = schema;
        this.functionsMap = functionsMap;
        this.validateEnums = validateEnums;
        this.nullFunctions = nullFunctions;
    }
    parseObjectWithSelections(data, type, selections) {
        const fieldMap = type.getFields();
        const fn = (d, fieldNode) => this.treatSelection(d, fieldMap, fieldNode);
        return reduce(selections, fn, data);
    }
    treatSelection(data, fieldMap, fieldNode) {
        const name = fieldNode.name.value;
        const field = fieldMap[name];
        if (!field)
            return data;
        const key = fieldNode.alias ? fieldNode.alias.value : fieldNode.name.value;
        data[key] = this.treatValue(data[key], field.type, fieldNode);
        return data;
    }
    treatValue(value, givenType, fieldNode) {
        if (isNonNullType(givenType)) {
            return this.treatValueInternal(value, givenType, fieldNode);
        }
        else {
            return this.treatValueNullable(value, givenType, fieldNode);
        }
    }
    treatValueNullable(value, givenType, fieldNode) {
        const wrappedValue = this.treatValueInternal(value, givenType, fieldNode);
        return this.nullFunctions.parseValue(wrappedValue);
    }
    treatValueInternal(value, givenType, fieldNode) {
        const type = ensureNullable(givenType);
        if (isNone(value))
            return value;
        if (isScalarType(type)) {
            return this.parseScalar(value, type);
        }
        if (isEnumType(type)) {
            this.validateEnum(value, type);
            return value;
        }
        if (isListType(type)) {
            return this.parseArray(value, type, fieldNode);
        }
        return this.parseNestedObject(value, type, fieldNode);
    }
    parseScalar(value, type) {
        const fns = this.functionsMap[type.name] || type;
        return fns.parseValue(value);
    }
    validateEnum(value, type) {
        if (!this.validateEnums || !value)
            return;
        const enumValues = type.getValues().map((v) => v.value);
        if (!enumValues.includes(value)) {
            throw new GraphQLError(`enum "${type.name}" with invalid value`);
        }
    }
    parseArray(value, type, fieldNode) {
        return Array.isArray(value) ? value.map((v) => this.treatValue(v, type.ofType, fieldNode)) : value;
    }
    parseNestedObject(value, givenType, fieldNode) {
        if (!value || !fieldNode || !fieldNode.selectionSet || !fieldNode.selectionSet.selections.length) {
            return value;
        }
        const type = this.getObjectTypeFrom(value, givenType);
        return type ? this.parseObjectWithSelections(value, type, fieldNode.selectionSet.selections) : value;
    }
    getObjectTypeFrom(value, type) {
        if (isInputObjectType(type) || isObjectType(type))
            return type;
        if (!value.__typename)
            return null;
        const valueType = this.schema.getType(value.__typename);
        return isInputObjectType(valueType) || isObjectType(valueType) ? valueType : null;
    }
}
