/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type ChangePasswordInput = {
  userId: Scalars['Int'];
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type Contract = {
  __typename?: 'Contract';
  id: Scalars['Int'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  sunday: Scalars['Float'];
  monday: Scalars['Float'];
  tuesday: Scalars['Float'];
  wednesday: Scalars['Float'];
  thursday: Scalars['Float'];
  friday: Scalars['Float'];
  saturday: Scalars['Float'];
  weeklyWorkingTime: Scalars['Float'];
  availableRecoveryTime: Scalars['Float'];
};

export type CreateContractInput = {
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  sunday: Scalars['Float'];
  monday: Scalars['Float'];
  tuesday: Scalars['Float'];
  wednesday: Scalars['Float'];
  thursday: Scalars['Float'];
  friday: Scalars['Float'];
  saturday: Scalars['Float'];
};

export type CreateProjectInput = {
  name: Scalars['String'];
  pitch?: Maybe<Scalars['String']>;
  userIds?: Maybe<Array<Scalars['Int']>>;
};

export type CreateTimeLogInput = {
  userId: Scalars['Int'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  label?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['Int']>;
};

export type CreateTimeOffInput = {
  userId: Scalars['Int'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  type: TimeOffType;
};

export type CreateUserInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  birthDate: Scalars['DateTime'];
  contract: CreateContractInput;
};


export type Holiday = {
  __typename?: 'Holiday';
  id: Scalars['Int'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  label: Scalars['String'];
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createUser: User;
  changePassword: Scalars['Boolean'];
  createTimeOff: TimeOff;
  updateTimeOff: TimeOff;
  deleteTimeOff: Scalars['Int'];
  createTimeLog: TimeLog;
  updateTimeLog: TimeLog;
  deleteTimeLog: Scalars['Int'];
  createProject: Project;
  updateProject: Project;
  login?: Maybe<Scalars['String']>;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationCreateTimeOffArgs = {
  input: CreateTimeOffInput;
};


export type MutationUpdateTimeOffArgs = {
  input: UpdateTimeOffInput;
};


export type MutationDeleteTimeOffArgs = {
  id: Scalars['Int'];
};


export type MutationCreateTimeLogArgs = {
  input: CreateTimeLogInput;
};


export type MutationUpdateTimeLogArgs = {
  input: UpdateTimeLogInput;
};


export type MutationDeleteTimeLogArgs = {
  id: Scalars['Int'];
};


export type MutationCreateProjectArgs = {
  input: CreateProjectInput;
};


export type MutationUpdateProjectArgs = {
  input: UpdateProjectInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};

export type Project = {
  __typename?: 'Project';
  id: Scalars['Int'];
  name: Scalars['String'];
  pitch?: Maybe<Scalars['String']>;
  users: Array<User>;
};

export type Query = {
  __typename?: 'Query';
  users: Array<User>;
  user: User;
  timeOff: Array<TimeOff>;
  timeLogs: Array<TimeLog>;
  projects: Array<Project>;
  project: Project;
  me: User;
  holidays: Array<Holiday>;
};


export type QueryUserArgs = {
  id: Scalars['Int'];
};


export type QueryTimeOffArgs = {
  input?: Maybe<SearchTimeOffsInput>;
};


export type QueryTimeLogsArgs = {
  input?: Maybe<SearchTimeLogsInput>;
};


export type QueryProjectArgs = {
  id: Scalars['Int'];
};


export type QueryHolidaysArgs = {
  input: SearchHolidaysInput;
};

export type SearchHolidaysInput = {
  endDateBefore: Scalars['DateTime'];
  startDateAfter: Scalars['DateTime'];
};

export type SearchTimeLogsInput = {
  userId?: Maybe<Scalars['Int']>;
  endDateBefore?: Maybe<Scalars['DateTime']>;
  startDateAfter?: Maybe<Scalars['DateTime']>;
};

export type SearchTimeOffsInput = {
  userId?: Maybe<Scalars['Int']>;
  endDateBefore?: Maybe<Scalars['DateTime']>;
  startDateAfter?: Maybe<Scalars['DateTime']>;
};

export type TimeLog = {
  __typename?: 'TimeLog';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  user: User;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  label?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['Int']>;
  project?: Maybe<Project>;
};

export type TimeOff = {
  __typename?: 'TimeOff';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  user: User;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  type: TimeOffType;
};

export enum TimeOffType {
  Vacation = 'Vacation',
  Recovery = 'Recovery',
  WithoutPay = 'WithoutPay'
}

export type UpdateProjectInput = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  pitch?: Maybe<Scalars['String']>;
  userIds?: Maybe<Array<Scalars['Int']>>;
};

export type UpdateTimeLogInput = {
  id: Scalars['Int'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  label?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['Int']>;
};

export type UpdateTimeOffInput = {
  id: Scalars['Int'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  type?: Maybe<TimeOffType>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  role: UserRole;
  email: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  birthDate: Scalars['DateTime'];
  contracts: Array<Contract>;
};

export enum UserRole {
  User = 'User',
  Admin = 'Admin'
}

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'login'>
);

export type HolidaysQueryVariables = Exact<{
  input: SearchHolidaysInput;
}>;


export type HolidaysQuery = (
  { __typename?: 'Query' }
  & { holidays: Array<(
    { __typename?: 'Holiday' }
    & Pick<Holiday, 'id' | 'label' | 'startDate' | 'endDate'>
  )> }
);

export type BaseProjectFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'name' | 'pitch'>
);

export type ProjectQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ProjectQuery = (
  { __typename?: 'Query' }
  & { project: (
    { __typename?: 'Project' }
    & { users: Array<(
      { __typename?: 'User' }
      & BaseUserFragment
    )> }
    & BaseProjectFragment
  ) }
);

export type ProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectsQuery = (
  { __typename?: 'Query' }
  & { projects: Array<(
    { __typename?: 'Project' }
    & { users: Array<(
      { __typename?: 'User' }
      & BaseUserFragment
    )> }
    & BaseProjectFragment
  )> }
);

export type CreateProjectMutationVariables = Exact<{
  input: CreateProjectInput;
}>;


export type CreateProjectMutation = (
  { __typename?: 'Mutation' }
  & { createProject: (
    { __typename?: 'Project' }
    & { users: Array<(
      { __typename?: 'User' }
      & BaseUserFragment
    )> }
    & BaseProjectFragment
  ) }
);

export type UpdateProjectMutationVariables = Exact<{
  input: UpdateProjectInput;
}>;


export type UpdateProjectMutation = (
  { __typename?: 'Mutation' }
  & { updateProject: (
    { __typename?: 'Project' }
    & { users: Array<(
      { __typename?: 'User' }
      & BaseUserFragment
    )> }
    & BaseProjectFragment
  ) }
);

export type BaseTimeLogFragment = (
  { __typename?: 'TimeLog' }
  & Pick<TimeLog, 'id' | 'startDate' | 'endDate' | 'label'>
);

export type CompleteTimeLogFragment = (
  { __typename?: 'TimeLog' }
  & Pick<TimeLog, 'userId' | 'projectId'>
  & { user: (
    { __typename?: 'User' }
    & BaseUserFragment
  ), project?: Maybe<(
    { __typename?: 'Project' }
    & BaseProjectFragment
  )> }
  & BaseTimeLogFragment
);

export type TimeLogsQueryVariables = Exact<{
  input?: Maybe<SearchTimeLogsInput>;
}>;


export type TimeLogsQuery = (
  { __typename?: 'Query' }
  & { timeLogs: Array<(
    { __typename?: 'TimeLog' }
    & CompleteTimeLogFragment
  )> }
);

export type CreateTimeLogMutationVariables = Exact<{
  input: CreateTimeLogInput;
}>;


export type CreateTimeLogMutation = (
  { __typename?: 'Mutation' }
  & { createTimeLog: (
    { __typename?: 'TimeLog' }
    & CompleteTimeLogFragment
  ) }
);

export type UpdateTimeLogMutationVariables = Exact<{
  input: UpdateTimeLogInput;
}>;


export type UpdateTimeLogMutation = (
  { __typename?: 'Mutation' }
  & { updateTimeLog: (
    { __typename?: 'TimeLog' }
    & CompleteTimeLogFragment
  ) }
);

export type DeleteTimeLogMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteTimeLogMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTimeLog'>
);

export type BaseTimeOffFragment = (
  { __typename?: 'TimeOff' }
  & Pick<TimeOff, 'id' | 'type' | 'startDate' | 'endDate'>
);

export type TimeOffsQueryVariables = Exact<{
  input?: Maybe<SearchTimeOffsInput>;
}>;


export type TimeOffsQuery = (
  { __typename?: 'Query' }
  & { timeOff: Array<(
    { __typename?: 'TimeOff' }
    & { user: (
      { __typename?: 'User' }
      & BaseUserFragment
    ) }
    & BaseTimeOffFragment
  )> }
);

export type CreateTimeOffMutationVariables = Exact<{
  input: CreateTimeOffInput;
}>;


export type CreateTimeOffMutation = (
  { __typename?: 'Mutation' }
  & { createTimeOff: (
    { __typename?: 'TimeOff' }
    & { user: (
      { __typename?: 'User' }
      & BaseUserFragment
    ) }
    & BaseTimeOffFragment
  ) }
);

export type UpdateTimeOffMutationVariables = Exact<{
  input: UpdateTimeOffInput;
}>;


export type UpdateTimeOffMutation = (
  { __typename?: 'Mutation' }
  & { updateTimeOff: (
    { __typename?: 'TimeOff' }
    & { user: (
      { __typename?: 'User' }
      & BaseUserFragment
    ) }
    & BaseTimeOffFragment
  ) }
);

export type DeleteTimeOffMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteTimeOffMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTimeOff'>
);

export type BaseUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'birthDate'>
);

export type BaseContractFragment = (
  { __typename?: 'Contract' }
  & Pick<Contract, 'id' | 'startDate' | 'endDate' | 'weeklyWorkingTime' | 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday'>
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'role'>
    & { contracts: Array<(
      { __typename?: 'Contract' }
      & Pick<Contract, 'availableRecoveryTime'>
      & BaseContractFragment
    )> }
    & BaseUserFragment
  ) }
);

export type UserQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & { contracts: Array<(
      { __typename?: 'Contract' }
      & Pick<Contract, 'availableRecoveryTime'>
      & BaseContractFragment
    )> }
    & BaseUserFragment
  ) }
);

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & BaseUserFragment
  )> }
);

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'User' }
    & { contracts: Array<(
      { __typename?: 'Contract' }
      & BaseContractFragment
    )> }
    & BaseUserFragment
  ) }
);

export type ChangeUserPasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;


export type ChangeUserPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export const BaseTimeLogFragmentDoc = gql`
    fragment BaseTimeLog on TimeLog {
  id
  startDate
  endDate
  label
}
    `;
export const BaseUserFragmentDoc = gql`
    fragment BaseUser on User {
  id
  email
  firstName
  lastName
  birthDate
}
    `;
export const BaseProjectFragmentDoc = gql`
    fragment BaseProject on Project {
  id
  name
  pitch
}
    `;
export const CompleteTimeLogFragmentDoc = gql`
    fragment CompleteTimeLog on TimeLog {
  ...BaseTimeLog
  userId
  user {
    ...BaseUser
  }
  projectId
  project {
    ...BaseProject
  }
}
    ${BaseTimeLogFragmentDoc}
${BaseUserFragmentDoc}
${BaseProjectFragmentDoc}`;
export const BaseTimeOffFragmentDoc = gql`
    fragment BaseTimeOff on TimeOff {
  id
  type
  startDate
  endDate
}
    `;
export const BaseContractFragmentDoc = gql`
    fragment BaseContract on Contract {
  id
  startDate
  endDate
  weeklyWorkingTime
  sunday
  monday
  tuesday
  wednesday
  thursday
  friday
  saturday
}
    `;
export const LoginDocument = gql`
    mutation Login($input: LoginInput!) {
  login(input: $input)
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const HolidaysDocument = gql`
    query Holidays($input: SearchHolidaysInput!) {
  holidays(input: $input) {
    id
    label
    startDate
    endDate
  }
}
    `;

/**
 * __useHolidaysQuery__
 *
 * To run a query within a React component, call `useHolidaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useHolidaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHolidaysQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHolidaysQuery(baseOptions: Apollo.QueryHookOptions<HolidaysQuery, HolidaysQueryVariables>) {
        return Apollo.useQuery<HolidaysQuery, HolidaysQueryVariables>(HolidaysDocument, baseOptions);
      }
export function useHolidaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HolidaysQuery, HolidaysQueryVariables>) {
          return Apollo.useLazyQuery<HolidaysQuery, HolidaysQueryVariables>(HolidaysDocument, baseOptions);
        }
export type HolidaysQueryHookResult = ReturnType<typeof useHolidaysQuery>;
export type HolidaysLazyQueryHookResult = ReturnType<typeof useHolidaysLazyQuery>;
export type HolidaysQueryResult = Apollo.QueryResult<HolidaysQuery, HolidaysQueryVariables>;
export const ProjectDocument = gql`
    query Project($id: Int!) {
  project(id: $id) {
    ...BaseProject
    users {
      ...BaseUser
    }
  }
}
    ${BaseProjectFragmentDoc}
${BaseUserFragmentDoc}`;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectQuery(baseOptions: Apollo.QueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
        return Apollo.useQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, baseOptions);
      }
export function useProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
          return Apollo.useLazyQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, baseOptions);
        }
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectQueryResult = Apollo.QueryResult<ProjectQuery, ProjectQueryVariables>;
export const ProjectsDocument = gql`
    query Projects {
  projects {
    ...BaseProject
    users {
      ...BaseUser
    }
  }
}
    ${BaseProjectFragmentDoc}
${BaseUserFragmentDoc}`;

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
        return Apollo.useQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, baseOptions);
      }
export function useProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
          return Apollo.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, baseOptions);
        }
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsLazyQueryHookResult = ReturnType<typeof useProjectsLazyQuery>;
export type ProjectsQueryResult = Apollo.QueryResult<ProjectsQuery, ProjectsQueryVariables>;
export const CreateProjectDocument = gql`
    mutation CreateProject($input: CreateProjectInput!) {
  createProject(input: $input) {
    ...BaseProject
    users {
      ...BaseUser
    }
  }
}
    ${BaseProjectFragmentDoc}
${BaseUserFragmentDoc}`;
export type CreateProjectMutationFn = Apollo.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, baseOptions);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;
export const UpdateProjectDocument = gql`
    mutation UpdateProject($input: UpdateProjectInput!) {
  updateProject(input: $input) {
    ...BaseProject
    users {
      ...BaseUser
    }
  }
}
    ${BaseProjectFragmentDoc}
${BaseUserFragmentDoc}`;
export type UpdateProjectMutationFn = Apollo.MutationFunction<UpdateProjectMutation, UpdateProjectMutationVariables>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>) {
        return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, baseOptions);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<UpdateProjectMutation, UpdateProjectMutationVariables>;
export const TimeLogsDocument = gql`
    query TimeLogs($input: SearchTimeLogsInput) {
  timeLogs(input: $input) {
    ...CompleteTimeLog
  }
}
    ${CompleteTimeLogFragmentDoc}`;

/**
 * __useTimeLogsQuery__
 *
 * To run a query within a React component, call `useTimeLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeLogsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTimeLogsQuery(baseOptions?: Apollo.QueryHookOptions<TimeLogsQuery, TimeLogsQueryVariables>) {
        return Apollo.useQuery<TimeLogsQuery, TimeLogsQueryVariables>(TimeLogsDocument, baseOptions);
      }
export function useTimeLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeLogsQuery, TimeLogsQueryVariables>) {
          return Apollo.useLazyQuery<TimeLogsQuery, TimeLogsQueryVariables>(TimeLogsDocument, baseOptions);
        }
export type TimeLogsQueryHookResult = ReturnType<typeof useTimeLogsQuery>;
export type TimeLogsLazyQueryHookResult = ReturnType<typeof useTimeLogsLazyQuery>;
export type TimeLogsQueryResult = Apollo.QueryResult<TimeLogsQuery, TimeLogsQueryVariables>;
export const CreateTimeLogDocument = gql`
    mutation CreateTimeLog($input: CreateTimeLogInput!) {
  createTimeLog(input: $input) {
    ...CompleteTimeLog
  }
}
    ${CompleteTimeLogFragmentDoc}`;
export type CreateTimeLogMutationFn = Apollo.MutationFunction<CreateTimeLogMutation, CreateTimeLogMutationVariables>;

/**
 * __useCreateTimeLogMutation__
 *
 * To run a mutation, you first call `useCreateTimeLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeLogMutation, { data, loading, error }] = useCreateTimeLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTimeLogMutation(baseOptions?: Apollo.MutationHookOptions<CreateTimeLogMutation, CreateTimeLogMutationVariables>) {
        return Apollo.useMutation<CreateTimeLogMutation, CreateTimeLogMutationVariables>(CreateTimeLogDocument, baseOptions);
      }
export type CreateTimeLogMutationHookResult = ReturnType<typeof useCreateTimeLogMutation>;
export type CreateTimeLogMutationResult = Apollo.MutationResult<CreateTimeLogMutation>;
export type CreateTimeLogMutationOptions = Apollo.BaseMutationOptions<CreateTimeLogMutation, CreateTimeLogMutationVariables>;
export const UpdateTimeLogDocument = gql`
    mutation UpdateTimeLog($input: UpdateTimeLogInput!) {
  updateTimeLog(input: $input) {
    ...CompleteTimeLog
  }
}
    ${CompleteTimeLogFragmentDoc}`;
export type UpdateTimeLogMutationFn = Apollo.MutationFunction<UpdateTimeLogMutation, UpdateTimeLogMutationVariables>;

/**
 * __useUpdateTimeLogMutation__
 *
 * To run a mutation, you first call `useUpdateTimeLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimeLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimeLogMutation, { data, loading, error }] = useUpdateTimeLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTimeLogMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimeLogMutation, UpdateTimeLogMutationVariables>) {
        return Apollo.useMutation<UpdateTimeLogMutation, UpdateTimeLogMutationVariables>(UpdateTimeLogDocument, baseOptions);
      }
export type UpdateTimeLogMutationHookResult = ReturnType<typeof useUpdateTimeLogMutation>;
export type UpdateTimeLogMutationResult = Apollo.MutationResult<UpdateTimeLogMutation>;
export type UpdateTimeLogMutationOptions = Apollo.BaseMutationOptions<UpdateTimeLogMutation, UpdateTimeLogMutationVariables>;
export const DeleteTimeLogDocument = gql`
    mutation DeleteTimeLog($id: Int!) {
  deleteTimeLog(id: $id)
}
    `;
export type DeleteTimeLogMutationFn = Apollo.MutationFunction<DeleteTimeLogMutation, DeleteTimeLogMutationVariables>;

/**
 * __useDeleteTimeLogMutation__
 *
 * To run a mutation, you first call `useDeleteTimeLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimeLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimeLogMutation, { data, loading, error }] = useDeleteTimeLogMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTimeLogMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTimeLogMutation, DeleteTimeLogMutationVariables>) {
        return Apollo.useMutation<DeleteTimeLogMutation, DeleteTimeLogMutationVariables>(DeleteTimeLogDocument, baseOptions);
      }
export type DeleteTimeLogMutationHookResult = ReturnType<typeof useDeleteTimeLogMutation>;
export type DeleteTimeLogMutationResult = Apollo.MutationResult<DeleteTimeLogMutation>;
export type DeleteTimeLogMutationOptions = Apollo.BaseMutationOptions<DeleteTimeLogMutation, DeleteTimeLogMutationVariables>;
export const TimeOffsDocument = gql`
    query TimeOffs($input: SearchTimeOffsInput) {
  timeOff(input: $input) {
    ...BaseTimeOff
    user {
      ...BaseUser
    }
  }
}
    ${BaseTimeOffFragmentDoc}
${BaseUserFragmentDoc}`;

/**
 * __useTimeOffsQuery__
 *
 * To run a query within a React component, call `useTimeOffsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeOffsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeOffsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTimeOffsQuery(baseOptions?: Apollo.QueryHookOptions<TimeOffsQuery, TimeOffsQueryVariables>) {
        return Apollo.useQuery<TimeOffsQuery, TimeOffsQueryVariables>(TimeOffsDocument, baseOptions);
      }
export function useTimeOffsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeOffsQuery, TimeOffsQueryVariables>) {
          return Apollo.useLazyQuery<TimeOffsQuery, TimeOffsQueryVariables>(TimeOffsDocument, baseOptions);
        }
export type TimeOffsQueryHookResult = ReturnType<typeof useTimeOffsQuery>;
export type TimeOffsLazyQueryHookResult = ReturnType<typeof useTimeOffsLazyQuery>;
export type TimeOffsQueryResult = Apollo.QueryResult<TimeOffsQuery, TimeOffsQueryVariables>;
export const CreateTimeOffDocument = gql`
    mutation CreateTimeOff($input: CreateTimeOffInput!) {
  createTimeOff(input: $input) {
    ...BaseTimeOff
    user {
      ...BaseUser
    }
  }
}
    ${BaseTimeOffFragmentDoc}
${BaseUserFragmentDoc}`;
export type CreateTimeOffMutationFn = Apollo.MutationFunction<CreateTimeOffMutation, CreateTimeOffMutationVariables>;

/**
 * __useCreateTimeOffMutation__
 *
 * To run a mutation, you first call `useCreateTimeOffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeOffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeOffMutation, { data, loading, error }] = useCreateTimeOffMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTimeOffMutation(baseOptions?: Apollo.MutationHookOptions<CreateTimeOffMutation, CreateTimeOffMutationVariables>) {
        return Apollo.useMutation<CreateTimeOffMutation, CreateTimeOffMutationVariables>(CreateTimeOffDocument, baseOptions);
      }
export type CreateTimeOffMutationHookResult = ReturnType<typeof useCreateTimeOffMutation>;
export type CreateTimeOffMutationResult = Apollo.MutationResult<CreateTimeOffMutation>;
export type CreateTimeOffMutationOptions = Apollo.BaseMutationOptions<CreateTimeOffMutation, CreateTimeOffMutationVariables>;
export const UpdateTimeOffDocument = gql`
    mutation UpdateTimeOff($input: UpdateTimeOffInput!) {
  updateTimeOff(input: $input) {
    ...BaseTimeOff
    user {
      ...BaseUser
    }
  }
}
    ${BaseTimeOffFragmentDoc}
${BaseUserFragmentDoc}`;
export type UpdateTimeOffMutationFn = Apollo.MutationFunction<UpdateTimeOffMutation, UpdateTimeOffMutationVariables>;

/**
 * __useUpdateTimeOffMutation__
 *
 * To run a mutation, you first call `useUpdateTimeOffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimeOffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimeOffMutation, { data, loading, error }] = useUpdateTimeOffMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTimeOffMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimeOffMutation, UpdateTimeOffMutationVariables>) {
        return Apollo.useMutation<UpdateTimeOffMutation, UpdateTimeOffMutationVariables>(UpdateTimeOffDocument, baseOptions);
      }
export type UpdateTimeOffMutationHookResult = ReturnType<typeof useUpdateTimeOffMutation>;
export type UpdateTimeOffMutationResult = Apollo.MutationResult<UpdateTimeOffMutation>;
export type UpdateTimeOffMutationOptions = Apollo.BaseMutationOptions<UpdateTimeOffMutation, UpdateTimeOffMutationVariables>;
export const DeleteTimeOffDocument = gql`
    mutation DeleteTimeOff($id: Int!) {
  deleteTimeOff(id: $id)
}
    `;
export type DeleteTimeOffMutationFn = Apollo.MutationFunction<DeleteTimeOffMutation, DeleteTimeOffMutationVariables>;

/**
 * __useDeleteTimeOffMutation__
 *
 * To run a mutation, you first call `useDeleteTimeOffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimeOffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimeOffMutation, { data, loading, error }] = useDeleteTimeOffMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTimeOffMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTimeOffMutation, DeleteTimeOffMutationVariables>) {
        return Apollo.useMutation<DeleteTimeOffMutation, DeleteTimeOffMutationVariables>(DeleteTimeOffDocument, baseOptions);
      }
export type DeleteTimeOffMutationHookResult = ReturnType<typeof useDeleteTimeOffMutation>;
export type DeleteTimeOffMutationResult = Apollo.MutationResult<DeleteTimeOffMutation>;
export type DeleteTimeOffMutationOptions = Apollo.BaseMutationOptions<DeleteTimeOffMutation, DeleteTimeOffMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...BaseUser
    role
    contracts {
      ...BaseContract
      availableRecoveryTime
    }
  }
}
    ${BaseUserFragmentDoc}
${BaseContractFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UserDocument = gql`
    query User($id: Int!) {
  user(id: $id) {
    ...BaseUser
    contracts {
      ...BaseContract
      availableRecoveryTime
    }
  }
}
    ${BaseUserFragmentDoc}
${BaseContractFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
    query Users {
  users {
    ...BaseUser
  }
}
    ${BaseUserFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    ...BaseUser
    contracts {
      ...BaseContract
    }
  }
}
    ${BaseUserFragmentDoc}
${BaseContractFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const ChangeUserPasswordDocument = gql`
    mutation ChangeUserPassword($input: ChangePasswordInput!) {
  changePassword(input: $input)
}
    `;
export type ChangeUserPasswordMutationFn = Apollo.MutationFunction<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>;

/**
 * __useChangeUserPasswordMutation__
 *
 * To run a mutation, you first call `useChangeUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserPasswordMutation, { data, loading, error }] = useChangeUserPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>) {
        return Apollo.useMutation<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>(ChangeUserPasswordDocument, baseOptions);
      }
export type ChangeUserPasswordMutationHookResult = ReturnType<typeof useChangeUserPasswordMutation>;
export type ChangeUserPasswordMutationResult = Apollo.MutationResult<ChangeUserPasswordMutation>;
export type ChangeUserPasswordMutationOptions = Apollo.BaseMutationOptions<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>;