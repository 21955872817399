import {
  Box,
  Card,
  CardHeader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import CakeIcon from '@material-ui/icons/Cake';
import MailIcon from '@material-ui/icons/Mail';
import React from 'react';
import { Link } from 'react-router-dom';
import { User as GQLUser } from '../../gql';
import { formatFr } from '../../utils/date';
import UserAvatar from './UserAvatar';

type User = Pick<
  GQLUser,
  'id' | 'firstName' | 'lastName' | 'email' | 'birthDate'
>;

export interface UserPreviewCardProps {
  user: User;
}

const UserPreviewCard = ({ user }: UserPreviewCardProps) => {
  return (
    <Card>
      <CardHeader
        title={
          <Box display="flex" alignItems="center" component="span">
            <Box mr={2} color="initial">
              <UserAvatar user={user} />
            </Box>
            <Link to={`/users/${user.id}`}>
              <Typography
                color="textPrimary"
                variant="h5"
              >{`${user.firstName} ${user.lastName}`}</Typography>
            </Link>
          </Box>
        }
      ></CardHeader>

      <Box px={1}>
        <List dense>
          <ListItem>
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary={user.email}></ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <CakeIcon />
            </ListItemIcon>
            <ListItemText
              primary={formatFr(user.birthDate, 'd MMMM yyyy')}
            ></ListItemText>
          </ListItem>
        </List>
      </Box>
    </Card>
  );
};

export default UserPreviewCard;
