import { gql } from '@apollo/client';
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { useMe } from '../auth/me-context';
import TimeLogFormCard, {
  TimeLogFormBag,
  TimeLogFormValue,
} from '../components/events/time-logs/TimeLogFormCard';
import CircularProgressBox from '../components/utils/CircularProgressBox';
import { CHRONO_CURRENT_TIME_LOG_STORAGE_KEY } from '../constants';
import {
  Project as GQLProject,
  TimeLog,
  useCreateTimeLogMutation,
  useProjectsQuery,
  User as GQLUser,
} from '../gql';

type Project = Pick<GQLProject, 'id' | 'name' | 'pitch'> & {
  users?: Pick<
    GQLUser,
    'id' | 'email' | 'firstName' | 'lastName' | 'birthDate'
  >[];
};

interface ChronoContext {
  projects: Project[];
  chronoCurrentTimeLog: ChronoCurrentTimeLog | null;
  save: () => void;
  setChronoCurrentTimeLog: (value: ChronoCurrentTimeLog | null) => void;
}

export interface ChronoProviderProps {
  children: React.ReactNode;
}

export type ChronoCurrentTimeLog = Pick<
  TimeLog,
  'label' | 'projectId' | 'startDate'
>;

const chornoContext = React.createContext<ChronoContext | null>(null);

const getCurrentTimeLogFromLocalStorage = (): ChronoCurrentTimeLog | null => {
  const data = localStorage.getItem(CHRONO_CURRENT_TIME_LOG_STORAGE_KEY);
  if (data) {
    try {
      const c = JSON.parse(data);
      return {
        label: c.label,
        projectId: c.projectId,
        startDate: new Date(c.startDate),
      };
    } catch (error) {
      return null;
    }
  }

  try {
    const oldStart = localStorage.getItem('logstart');
    if (oldStart) {
      return {
        label: localStorage.getItem('loglabel') || undefined,
        projectId:
          (localStorage.getItem('logproject') &&
            parseInt(localStorage.getItem('logproject')!, 10)) ||
          undefined,
        startDate: new Date(oldStart),
      };
    }
  } catch (error) {
    return null;
  }

  return null;
};

const saveCurrentTimeLogToLocalStorage = (
  value: ChronoCurrentTimeLog | null,
) => {
  if (value) {
    localStorage.setItem(
      CHRONO_CURRENT_TIME_LOG_STORAGE_KEY,
      JSON.stringify(value),
    );
  } else {
    localStorage.removeItem(CHRONO_CURRENT_TIME_LOG_STORAGE_KEY);
  }
};

export const ChronoProvider = ({ children }: ChronoProviderProps) => {
  const me = useMe();
  const { data: projectsData, loading: projectsLoading } = useProjectsQuery();
  const [createTimeLog, { loading: createTimeLogLoading }] =
    useCreateTimeLogMutation();
  /** */
  const projects = (projectsData && projectsData.projects) || [];
  const myProjects = projects.filter((p) =>
    p.users.map((u) => u.id).includes(me.id),
  );
  const [currentTimeLog, setCurrentTimeLog] = React.useState(
    getCurrentTimeLogFromLocalStorage(),
  );
  const [dialogOpened, setDialogOpened] = React.useState(false);
  const loading = createTimeLogLoading || projectsLoading;

  /** */
  const loadData = () => {
    setCurrentTimeLog(getCurrentTimeLogFromLocalStorage());
  };

  const save = () => {
    setDialogOpened(true);
  };

  const closeDialog = () => {
    if (loading) return;
    setDialogOpened(false);
  };

  const reset = () => {
    setCurrentTimeLog(null);
    closeDialog();
  };

  /** */
  const onTimeLogSumbit = async (
    values: TimeLogFormValue,
    bag: TimeLogFormBag,
  ) => {
    try {
      await createTimeLog({
        variables: { input: { userId: me.id, ...values } },
        refetchQueries: ['Me'],
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              timeLogs(existing = []) {
                const newCacheRef = cache.writeFragment({
                  data: data && data.createTimeLog,
                  fragment: gql`
                    fragment NewTimeLog on TimeLog {
                      id
                      type
                    }
                  `,
                });
                return [...existing, newCacheRef];
              },
            },
          });
        },
      });
      reset();
    } catch (error) {
      bag.setStatus({ apolloError: error });
      bag.setSubmitting(false);
    }
  };

  /** */
  React.useEffect(() => {
    saveCurrentTimeLogToLocalStorage(currentTimeLog);
  }, [currentTimeLog]);

  React.useEffect(() => {
    loadData();
  }, []);

  return (
    <chornoContext.Provider
      value={{
        projects: myProjects,
        chronoCurrentTimeLog: currentTimeLog,
        save,
        setChronoCurrentTimeLog: setCurrentTimeLog,
      }}
    >
      {children}

      <Dialog fullWidth maxWidth="md" open={dialogOpened} onClose={closeDialog}>
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Session de travail</Typography>

            <Box>
              <IconButton disabled={loading} onClick={reset}>
                <DeleteIcon />
              </IconButton>
              <IconButton disabled={loading} onClick={closeDialog}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>

        {currentTimeLog && (
          <>
            {projectsLoading ? (
              <CircularProgressBox />
            ) : (
              <TimeLogFormCard
                projects={myProjects}
                initialValues={{
                  startDate: currentTimeLog.startDate,
                  endDate: new Date(),
                  label: currentTimeLog.label || '',
                  projectId: currentTimeLog.projectId || undefined,
                }}
                onSubmit={onTimeLogSumbit}
              />
            )}
          </>
        )}
      </Dialog>
    </chornoContext.Provider>
  );
};

export const useChrono = () => {
  const ctx = React.useContext(chornoContext);
  if (!ctx) throw new Error('useChrono must be used within a ChronoProvider');
  return ctx;
};
