import {
  Box,
  Collapse,
  Fab,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import { differenceInSeconds } from 'date-fns';
import React from 'react';
import { useChrono } from '../../../chrono/chrono-context';
import { TimeLog } from '../../../gql';
import { formatDistanceToNowStrictFr } from '../../../utils/date';
import useInterval from '../../../utils/hooks/use-interval';
import ProjectAvatar from '../../projects/ProjectAvatar';
import ChronoTimeLogPopopver, {
  ChronoTimeLogPopoverValues,
} from './ChronoTimeLogPopopver';

export type ChronoCurrentTimeLog = Pick<
  TimeLog,
  'label' | 'projectId' | 'startDate'
>;

export interface ChronoBag {
  reset: () => void;
}

const useStyles = makeStyles((theme) => ({
  timeStr: {
    textAlign: 'center',
    fontSize: 9,
  },
}));

const Chrono = () => {
  const { chronoCurrentTimeLog, projects, save, setChronoCurrentTimeLog } =
    useChrono();
  const [timeStr, setStimeStr] = React.useState<string | undefined>();
  const [popoverAnchorEl, setPopoverAnchorEl] =
    React.useState<HTMLElement | null>(null);
  const classes = useStyles();
  const currentProject =
    chronoCurrentTimeLog &&
    chronoCurrentTimeLog.projectId &&
    projects.find((p) => p.id === chronoCurrentTimeLog.projectId);

  /** */
  const updateTime = () => {
    if (chronoCurrentTimeLog) {
      const dateDiff = differenceInSeconds(
        new Date(),
        chronoCurrentTimeLog.startDate,
      );
      if (dateDiff < 60) {
        setStimeStr(`< 1 minute`);
      } else {
        setStimeStr(
          `~ ${formatDistanceToNowStrictFr(chronoCurrentTimeLog.startDate)}`,
        );
      }
    } else {
      setStimeStr(undefined);
    }
  };

  const openTimeLogFormPopover = (e: React.MouseEvent<HTMLElement>) => {
    setPopoverAnchorEl(e.currentTarget);
  };

  const closeTimeLogFormPopover = () => {
    setPopoverAnchorEl(null);
  };

  /** */
  const onFabClick = () => {
    if (chronoCurrentTimeLog) {
      save();
    } else {
      setChronoCurrentTimeLog({
        startDate: new Date(),
      });
    }
    updateTime();
  };

  const onTimeLogPorpoverChange = (values: ChronoTimeLogPopoverValues) => {
    setChronoCurrentTimeLog({
      ...chronoCurrentTimeLog,
      ...values,
      startDate: chronoCurrentTimeLog?.startDate,
    });
  };

  /** */
  useInterval(updateTime, !!chronoCurrentTimeLog ? 1000 : null);

  /** */
  return (
    <Box py={2} display="flex" flexDirection="column" alignItems="center">
      <Fab
        size="small"
        color={!!chronoCurrentTimeLog ? 'primary' : 'default'}
        onClick={onFabClick}
      >
        {!!chronoCurrentTimeLog ? <StopIcon /> : <PlayArrowIcon />}
      </Fab>

      <Collapse in={!!chronoCurrentTimeLog}>
        <Box mt={2} display="flex" flexDirection="column" alignItems="center">
          <IconButton onClick={openTimeLogFormPopover}>
            {currentProject ? (
              <ProjectAvatar project={currentProject} />
            ) : (
              <HelpIcon />
            )}
          </IconButton>
          <Box mt={1}>
            <Typography className={classes.timeStr}>
              {timeStr ? timeStr : <>&nbsp;</>}
            </Typography>
          </Box>
        </Box>
      </Collapse>
      <ChronoTimeLogPopopver
        values={
          chronoCurrentTimeLog
            ? {
                label: chronoCurrentTimeLog.label || undefined,
                projectId: chronoCurrentTimeLog.projectId || undefined,
              }
            : {}
        }
        anchorEl={popoverAnchorEl}
        projects={projects}
        onChange={onTimeLogPorpoverChange}
        onClose={closeTimeLogFormPopover}
      />
    </Box>
  );
};

export default Chrono;
