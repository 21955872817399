import { Avatar, makeStyles } from '@material-ui/core';
import React from 'react';
import { Project as GQLProject } from '../../gql';
import { getProjectColor } from '../../utils/colors';

type AvatarSize = 'default' | 'small' | 'big';

type Project = Pick<GQLProject, 'name'>;

export interface ProjectAvatarProps {
  project: Project;
  size?: AvatarSize;
}

const useStyles = makeStyles((theme) => ({
  avatar: ({ color, size }: { color: string; size: AvatarSize }) => ({
    background: color,
    color: theme.palette.getContrastText(color),

    ...(size === 'big'
      ? {
          width: theme.spacing(7),
          height: theme.spacing(7),
        }
      : {}),
    ...(size === 'small'
      ? {
          width: theme.spacing(3),
          height: theme.spacing(3),
          fontSize: theme.typography.overline.fontSize,
        }
      : {}),
  }),
}));

const ProjectAvatar = ({ project, size = 'default' }: ProjectAvatarProps) => {
  const classes = useStyles({
    color: getProjectColor(project),
    size,
  });

  return (
    <Avatar
      className={classes.avatar}
      variant="rounded"
    >{`${project.name.charAt(0)}${project.name.charAt(1)}${project.name.charAt(
      2,
    )}`}</Avatar>
  );
};

export default ProjectAvatar;
