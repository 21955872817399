import {
  Box,
  Card,
  CardHeader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import React from 'react';
import { Link } from 'react-router-dom';
import { Project as GQLProject, User as GQLUser } from '../../gql';
import UserAvatar from '../users/UserAvatar';
import ProjectAvatar from './ProjectAvatar';

type Project = Pick<GQLProject, 'id' | 'name'> & {
  users?: Pick<GQLUser, 'id' | 'firstName' | 'lastName'>[];
};

export interface ProjectPreviewCardProps {
  project: Project;
}

const ProjectPreviewCard = ({ project }: ProjectPreviewCardProps) => {
  const hasUsers = project.users && project.users.length > 0;

  /** */
  return (
    <Card>
      <CardHeader
        title={
          <Box display="flex" alignItems="center" component="span">
            <Box mr={2} color="initial">
              <ProjectAvatar project={project} />
            </Box>
            <Link to={`/projects/${project.id}`}>
              <Typography color="textPrimary" variant="h5">
                {project.name}
              </Typography>
            </Link>
          </Box>
        }
      ></CardHeader>

      <Box px={1}>
        <List dense>
          {hasUsers && (
            <ListItem>
              <ListItemIcon>
                <PeopleAltIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Box display="flex">
                    {project.users!.map((user) => (
                      <Box
                        key={user.id}
                        mr={1}
                        display="flex"
                        alignItems="center"
                      >
                        <UserAvatar size="small" user={user}></UserAvatar>
                      </Box>
                    ))}
                  </Box>
                }
              ></ListItemText>
            </ListItem>
          )}
        </List>
      </Box>
    </Card>
  );
};

export default ProjectPreviewCard;
