// http://www.heropatterns.com/
/**
background-color: #5e60ce;
background-image: url(" d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");
 */

interface Patern {
  width: number;
  height: number;
  d: string;
  rule?: 'evenodd';
}

const PATTERN: { [k: string]: Patern } = {
  stripes: { width: 40, height: 1, d: 'M0 0h20v1H0z' },
  zigzag: {
    width: 40,
    height: 12,
    d: 'M0 6.172L6.172 0h5.656L0 11.828V6.172zm40 5.656L28.172 0h5.656L40 6.172v5.656zM6.172 12l12-12h3.656l12 12h-5.656L20 3.828 11.828 12H6.172zm12 0L20 10.172 21.828 12h-3.656z',
  },
  bankNote: {
    width: 100,
    height: 20,
    d: 'M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z',
  },
  tinyCheckers: {
    width: 8,
    height: 8,
    d: 'M0 0h4v4H0V0zm4 4h4v4H4V4z',
  },
};

export const genCssBackgroundImagePattern = (
  pattern: keyof typeof PATTERN,
  { color, opacity = 0.4 }: { color: string; opacity?: number },
) => {
  const gen = ({
    width,
    height,
    d,
    rule = 'evenodd',
    fill,
    opacity,
  }: {
    fill: string;
    opacity: number;
  } & Patern) => {
    return `url("data:image/svg+xml,%3Csvg width='${width}' height='${height}' viewBox='0 0 ${width} ${height}' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${d}' fill='%23${fill}' fill-opacity='${opacity}' fill-rule='${rule}'/%3E%3C/svg%3E ")`;
  };

  return gen({ ...PATTERN[pattern], opacity, fill: color.slice(1) });
};
