import {
  Box,
  Dialog,
  IconButton,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { useMe } from '../auth/me-context';
import RecoveryFormCard, {
  RecoveryFormBag,
  RecoveryFormValue,
} from '../components/events/recoveries/RecoveryFormCard';
import TimeLogFormCard, {
  TimeLogFormBag,
  TimeLogFormValue,
} from '../components/events/time-logs/TimeLogFormCard';
import VacationFormCard, {
  VacationFormBag,
  VacationFormValues,
} from '../components/events/vacations/VacationFormCard';
import CircularProgressBox from '../components/utils/CircularProgressBox';
import {
  TimeOffType,
  useDeleteTimeLogMutation,
  useDeleteTimeOffMutation,
  useProjectsQuery,
  useUpdateTimeLogMutation,
  useUpdateTimeOffMutation,
} from '../gql';
import { CalendarUserEvent } from '../interfaces';

export interface EditEventDialogContainerProps {
  event?: CalendarUserEvent;
  onClose: () => void;
}

const calendarEventToDialogTitle = (event: CalendarUserEvent) => {
  if (event.resource.calendarEventType === 'timeoff') {
    if (
      event.resource.type === TimeOffType.Vacation ||
      event.resource.type === TimeOffType.WithoutPay
    ) {
      return 'Congés';
    } else if (event.resource.type === TimeOffType.Recovery) {
      return 'Récupération';
    }
  } else if (event.resource.calendarEventType === 'timelog') {
    return 'Session de travail';
  }
  return '';
};

const EditEventDialogContainer = ({
  event,
  onClose,
}: EditEventDialogContainerProps) => {
  const me = useMe();
  const { data: projectsData, loading: projectsLoading } = useProjectsQuery();
  const [updateTimeOff, { loading: updateTimeOffLoading }] =
    useUpdateTimeOffMutation();
  const [updateTimeLog, { loading: updateTimeLogLoading }] =
    useUpdateTimeLogMutation();
  const [deleteTimeOff, { loading: deleteTimeOffLoading }] =
    useDeleteTimeOffMutation();
  const [deleteTimeLog, { loading: deleteTimeLogLoading }] =
    useDeleteTimeLogMutation();
  /** */
  const itIsMine = event && event.resource.user.id === me.id;
  const loading =
    projectsLoading ||
    updateTimeOffLoading ||
    updateTimeLogLoading ||
    deleteTimeOffLoading ||
    deleteTimeLogLoading;
  const myProjects = ((projectsData && projectsData.projects) || []).filter(
    (p) => p.users && p.users.map((u) => u.id).includes(me.id),
  );

  /** */
  const _onClose = () => {
    if (loading) return;
    onClose();
  };

  const onTimeOffFormSubmit = async (
    values: VacationFormValues | RecoveryFormValue,
    bag: VacationFormBag | RecoveryFormBag,
  ) => {
    if (!event) return;

    try {
      await updateTimeOff({
        variables: { input: { id: event.resource.id, ...values } },
        refetchQueries: ['Me'],
      });
      onClose();
    } catch (error) {
      bag.setStatus({ apolloError: error });
      bag.setSubmitting(false);
    }
  };

  const onTimeLogSumbit = async (
    values: TimeLogFormValue,
    bag: TimeLogFormBag,
  ) => {
    if (!event) return;

    try {
      await updateTimeLog({
        variables: { input: { id: event.resource.id, ...values } },
        refetchQueries: ['Me'],
      });
      onClose();
    } catch (error) {
      bag.setStatus({ apolloError: error });
      bag.setSubmitting(false);
    }
  };

  const onDeleteClick = async () => {
    if (!event) return;

    const {
      resource: { calendarEventType, ...resource },
    } = event;

    if (calendarEventType === 'timeoff') {
      await deleteTimeOff({
        variables: { id: resource.id },
        refetchQueries: ['Me'],
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              timeOff(existing = [], { readField }) {
                return [
                  ...existing.filter(
                    (taskRef: any) =>
                      readField('id', taskRef) !== data!.deleteTimeOff,
                  ),
                ];
              },
            },
          });
        },
      });
      onClose();
    } else if (calendarEventType === 'timelog') {
      await deleteTimeLog({
        variables: { id: resource.id },
        refetchQueries: ['Me'],
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              timeLogs(existing = [], { readField }) {
                return [
                  ...existing.filter(
                    (taskRef: any) =>
                      readField('id', taskRef) !== data!.deleteTimeLog,
                  ),
                ];
              },
            },
          });
        },
      });
      onClose();
    }
  };

  /** */
  return (
    <Dialog fullWidth maxWidth="md" open={!!event} onClose={_onClose}>
      <Box p={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            {event ? calendarEventToDialogTitle(event) : ''}
          </Typography>

          <Box>
            {itIsMine && (
              <IconButton disabled={loading} onClick={onDeleteClick}>
                <DeleteIcon />
              </IconButton>
            )}
            <IconButton disabled={loading} onClick={_onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>

      {event && (
        <>
          {event.resource.calendarEventType === 'timeoff' && (
            <>
              {(event.resource.type === TimeOffType.Vacation ||
                event.resource.type === TimeOffType.WithoutPay) && (
                <VacationFormCard
                  initialValues={{
                    startDate: event.resource.startDate,
                    endDate: event.resource.endDate,
                    type: event.resource.type,
                  }}
                  disabled={!itIsMine}
                  onSubmit={onTimeOffFormSubmit}
                />
              )}

              {event.resource.type === TimeOffType.Recovery && (
                <RecoveryFormCard
                  initialValues={{
                    startDate: event.resource.startDate,
                    endDate: event.resource.endDate,
                    type: event.resource.type,
                  }}
                  disabled={!itIsMine}
                  onSubmit={onTimeOffFormSubmit}
                />
              )}
            </>
          )}
          {event.resource.calendarEventType === 'timelog' && (
            <>
              {projectsLoading ? (
                <CircularProgressBox />
              ) : (
                <TimeLogFormCard
                  initialValues={{
                    startDate: event.resource.startDate,
                    endDate: event.resource.endDate,
                    label: event.resource.label || undefined,
                    projectId: event.resource.projectId || undefined,
                  }}
                  projects={myProjects}
                  disabled={!itIsMine}
                  onSubmit={onTimeLogSumbit}
                />
              )}
            </>
          )}
        </>
      )}

      {loading && <LinearProgress />}
    </Dialog>
  );
};

export default EditEventDialogContainer;
