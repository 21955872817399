import { makeStyles } from '@material-ui/core';
import { genCssBackgroundImagePattern } from '../../utils/background-patterns';
import Color from 'color';

const BORDER_COLOR = '#ffffff3b';
const TIME_OFF_COLOR = '#ffd166'; //'#5e60ce'; //'#9cca77'; //'#48bfe3';

export const useCalendatStyles = makeStyles((theme) => ({
  calendarContainer: {
    '& .rbc-calendar': {
      '& .rbc-month-view': {
        borderRadius: theme.shape.borderRadius,
        borderColor: BORDER_COLOR,

        '& .rbc-month-header': {
          color: theme.palette.text.secondary,
          borderColor: BORDER_COLOR,

          '& .rbc-header': {
            borderColor: BORDER_COLOR,
            textTransform: 'capitalize',
          },
        },

        '& .rbc-month-row': {
          borderColor: BORDER_COLOR,

          '& .rbc-row-bg': {
            '& .rbc-day-bg': {
              borderColor: BORDER_COLOR,

              '&.rbc-off-range-bg': {
                backgroundColor: 'transparent',
                color: theme.palette.text.secondary,
              },
              '&.rbc-today': {
                backgroundColor: "transparent",
                border: `2px solid ${theme.palette.secondary.main}`
              },
              '&.rbc-selected-cell': {
                backgroundColor: theme.palette.primary.main,
              },
            },
          },

          '& .rbc-row-content': {
            '& .rbc-row': {
              '& .rbc-date-cell': {
                '&.rbc-off-range': {},
                '&.rbc-now': {
                  // color: theme.palette.getContrastText(TIME_OFF_COLOR),
                },
              },

              '& .rbc-row-segment': {
                '& .rbc-event': {
                  '& .rbc-event-content': {
                    fontSize: theme.typography.overline.fontSize,

                    '&:first-letter': {
                      textTransform: 'capitalize',
                    },
                  },
                },
              },
            },
          },
        },

      

    
        /*
           '& > *': {
              display: 'none',
            },
          */
      },
    },
  },
  timeOffEvent: {
    border: 'none',

    color: theme.palette.getContrastText(TIME_OFF_COLOR),
    backgroundColor: TIME_OFF_COLOR,
    backgroundImage: genCssBackgroundImagePattern('bankNote', {
      color: Color(TIME_OFF_COLOR).darken(0.3).hex(),
    }),
  },
  holidayEvent: {
    border: 'none',

    color: theme.palette.getContrastText(
      Color(TIME_OFF_COLOR).darken(0.3).hex(),
    ),
    backgroundColor: Color(TIME_OFF_COLOR).darken(0.3).hex(),
    backgroundImage: genCssBackgroundImagePattern('zigzag', {
      color: TIME_OFF_COLOR,
    }),
  },
}));
