import { Box, Button, CardContent, LinearProgress } from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import * as Yup from 'yup';
import ApolloErrorAlert from '../errors/ApolloErrorAlert';

export interface UserPasswordFormProps {
  initialValues?: Partial<UserPasswordFormValues>;
  onSubmit: (
    values: UserPasswordFormValues,
    formikHelpers: UserPasswordFormBag,
  ) => void;
}

export interface UserPasswordFormValues {
  password: string;
  newPassword: string;
  newPassword2: string;
}

export type UserPasswordFormBag = FormikHelpers<UserPasswordFormValues>;

const defaultInitialValues: UserPasswordFormValues = {
  password: '',
  newPassword: '',
  newPassword2: '',
};

const UserPasswordFormValuesSchema = Yup.object().shape({
  password: Yup.string().required('Required'),
  newPassword: Yup.string().required('Required'),
  newPassword2: Yup.string()
    .oneOf(
      [Yup.ref('newPassword'), null],
      'Les mots de passe doivent correspondre',
    )
    .required(),
});

const UserPasswordFormCard = ({
  initialValues,
  onSubmit,
}: UserPasswordFormProps) => {
  return (
    <Formik
      initialValues={{ ...defaultInitialValues, ...initialValues }}
      validationSchema={UserPasswordFormValuesSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, status }) => (
        <Form>
          {status && status.apolloError && (
            <CardContent>
              <ApolloErrorAlert error={status.apolloError} />
            </CardContent>
          )}

          <CardContent>
            <Field
              fullWidth
              margin="normal"
              variant="outlined"
              name="password"
              type="password"
              label="Ancien mot de passe"
              component={TextField}
            />

            <Field
              fullWidth
              margin="normal"
              variant="outlined"
              name="newPassword"
              type="password"
              label="Nouveau mot de passe"
              component={TextField}
            />

            <Field
              fullWidth
              margin="normal"
              variant="outlined"
              name="newPassword2"
              type="password"
              label="Répéter le nouveau mot de passe"
              component={TextField}
            />
          </CardContent>

          <CardContent>
            <Box p={2} display="flex" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
              >
                Valider
              </Button>
            </Box>
          </CardContent>

          {isSubmitting && <LinearProgress />}
        </Form>
      )}
    </Formik>
  );
};

export default UserPasswordFormCard;
