import {
  Box,
  InputAdornment,
  Popover,
  PopoverProps,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { Project as GQLProject } from '../../../gql';
import ProjectAvatar from '../../projects/ProjectAvatar';

type Project = Pick<GQLProject, 'id' | 'name'>;

export interface ChronoTimeLogPopopverProps
  extends Pick<PopoverProps, 'anchorEl'> {
  projects: Project[];
  values: ChronoTimeLogPopoverValues;
  onChange: (values: ChronoTimeLogPopoverValues) => void;
  onClose: () => void;
}

export interface ChronoTimeLogPopoverValues {
  label?: string;
  projectId?: number;
}

const ChronoTimeLogPopopver = ({
  values,
  projects,
  anchorEl,
  onChange,
  onClose,
}: ChronoTimeLogPopopverProps) => {
  const currentProject =
    (values.projectId && projects.find((p) => p.id === values.projectId)) ||
    null;

  /** */
  const onLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ ...values, label: e.target.value });
  };

  const onProjectChange = (_: any, newValue: Project | null) => {
    onChange({ ...values, projectId: newValue ? newValue.id : undefined });
  };

  /** */
  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      onClose={onClose}
    >
      <Box p={2} minWidth={350}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onClose();
          }}
        >
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            label="Label"
            value={values.label || ''}
            onChange={onLabelChange}
          />

          <Autocomplete
            value={currentProject}
            options={projects}
            getOptionLabel={(option) => `${option.name}`}
            getOptionSelected={(option, value) => option.id === value.id}
            renderOption={(option) => (
              <>
                <Box mr={2} component="span">
                  <ProjectAvatar size="small" project={option} />
                </Box>
                <span>{`${option.name}`}</span>
              </>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Selectionner un projet"
                variant="outlined"
                margin="normal"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: currentProject ? (
                    <InputAdornment position="start">
                      <ProjectAvatar
                        size="small"
                        project={currentProject}
                      ></ProjectAvatar>
                    </InputAdornment>
                  ) : undefined,
                }}
              />
            )}
            onChange={onProjectChange}
          />
          <button
            style={{
              position: 'absolute',
              left: -900000,
            }}
            type="submit"
          ></button>
        </form>
      </Box>
    </Popover>
  );
};

export default ChronoTimeLogPopopver;
