import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  LinearProgress,
} from '@material-ui/core';
import { isAfter, subHours } from 'date-fns';
import { addHours, endOfDay, startOfDay } from 'date-fns/esm';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { DatePicker } from 'formik-material-ui-pickers';
import React from 'react';
import * as Yup from 'yup';
import { TimeOffType } from '../../../gql';
import ApolloErrorAlert from '../../errors/ApolloErrorAlert';

export interface VacationFormCardProps {
  initialValues?: Partial<VacationFormValues>;
  disabled?: boolean;
  onSubmit: (values: VacationFormValues, bag: VacationFormBag) => void;
}

export interface VacationFormValues {
  startDate: Date;
  endDate: Date;
  type: TimeOffType.Vacation | TimeOffType.WithoutPay;
}

export type VacationFormBag = FormikHelpers<VacationFormValues>;

const VacationFormValuesSchema = Yup.object().shape({
  startDate: Yup.date().required('Required'),
  endDate: Yup.date().required('Required'),
});

const defaultValues: VacationFormValues = {
  startDate: new Date(),
  endDate: new Date(),
  type: TimeOffType.Vacation,
};

const VacationFormCard = ({
  initialValues,
  disabled = false,
  onSubmit,
}: VacationFormCardProps) => {
  const [endDateError, setEndDateError] = React.useState<string | undefined>(
    undefined,
  );

  /** */
  const _onSubmit = ({startDate, endDate, ..._values}: VacationFormValues, bag: VacationFormBag) => {
    const values = {
      startDate: addHours(startOfDay(startDate), 3),
      endDate: subHours(endOfDay(endDate), 3),
      ..._values
    }


    setEndDateError(undefined);
    if (!isAfter(values.endDate, values.startDate)) {
      setEndDateError(
        'La date de fin doit être date postérieure à la date de début',
      );
      bag.setSubmitting(false);
      return;
    }
    onSubmit(values, bag);
  };

  /** */
  return (
    <Formik
      initialValues={{ ...defaultValues, ...initialValues }}
      validationSchema={VacationFormValuesSchema}
      onSubmit={_onSubmit}
    >
      {({ status, values, isSubmitting, setFieldValue }) => (
        <Form>
          <Card>
            {status && status.apolloError && (
              <CardContent>
                <ApolloErrorAlert error={status.apolloError} />
              </CardContent>
            )}

            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    margin="normal"
                    inputVariant="outlined"
                    name="startDate"
                    label="Début"
                    format="EEEE d MMM yyyy"
                    disabled={disabled || isSubmitting}
                    component={DatePicker}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    error={!!endDateError}
                    margin="normal"
                    inputVariant="outlined"
                    name="endDate"
                    label="Fin"
                    format="EEEE d MMM yyyy"
                    helperText={endDateError || ''}
                    disabled={disabled || isSubmitting}
                    component={DatePicker}
                  />
                </Grid>
              </Grid>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.type === TimeOffType.WithoutPay}
                    disabled={disabled || isSubmitting}
                    onChange={(_, checked) => {
                      setFieldValue(
                        'type',
                        checked ? TimeOffType.WithoutPay : TimeOffType.Vacation,
                      );
                    }}
                  />
                }
                label="Sans solde"
              />
            </CardContent>

            {!disabled && (
              <CardContent>
                <Box display="flex" justifyContent="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={disabled || isSubmitting}
                  >
                    Valider
                  </Button>
                </Box>
              </CardContent>
            )}

            {isSubmitting && <LinearProgress />}
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default VacationFormCard;
