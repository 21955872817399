import { ApolloLink, Observable } from "@apollo/client/core";
import { isInputType, isLeafType } from "graphql";
import { GraphQLError } from "graphql/error/GraphQLError";
import pickBy from "lodash.pickby";
import defaultNullFunctions from "./default-null-functions";
import { mapIfArray } from "./map-if-array";
import { isListTypeNode, isNonNullTypeNode, isOperationDefinitionNode } from "./node-types";
import { Serializer } from "./serializer";
import { treatResult } from "./treat-result";
export class ScalarApolloLink extends ApolloLink {
    constructor(pars) {
        super();
        this.schema = pars.schema;
        this.typesMap = pars.typesMap || {};
        this.validateEnums = pars.validateEnums || false;
        this.removeTypenameFromInputs = pars.removeTypenameFromInputs || false;
        this.nullFunctions = pars.nullFunctions || defaultNullFunctions;
        const leafTypesMap = pickBy(this.schema.getTypeMap(), isLeafType);
        this.functionsMap = Object.assign(Object.assign({}, leafTypesMap), this.typesMap);
        this.serializer = new Serializer(this.schema, this.functionsMap, this.removeTypenameFromInputs, this.nullFunctions);
    }
    // ApolloLink code based on https://github.com/with-heart/apollo-link-response-resolver
    request(givenOperation, forward) {
        const operation = this.cleanVariables(givenOperation);
        return new Observable((observer) => {
            let sub;
            try {
                sub = forward(operation).subscribe({
                    next: (result) => {
                        try {
                            observer.next(this.parse(operation, result));
                        }
                        catch (treatError) {
                            const errors = result.errors ? [...result.errors] : [];
                            if (treatError instanceof GraphQLError) {
                                errors.push(treatError);
                            }
                            observer.next({ errors });
                        }
                    },
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                });
            }
            catch (e) {
                observer.error(e);
            }
            return () => {
                if (sub)
                    sub.unsubscribe();
            };
        });
    }
    parse(operation, result) {
        return treatResult({
            operation,
            result,
            functionsMap: this.functionsMap,
            schema: this.schema,
            validateEnums: this.validateEnums,
            nullFunctions: this.nullFunctions,
        });
    }
    /**
     * mutate the operation object with the serialized variables
     * @param operation
     */
    cleanVariables(operation) {
        const o = operation.query.definitions.find(isOperationDefinitionNode);
        const varDefs = (o === null || o === void 0 ? void 0 : o.variableDefinitions) || [];
        varDefs.forEach((vd) => {
            const key = vd.variable.name.value;
            operation.variables[key] = this.serialize(operation.variables[key], vd.type);
        });
        return operation;
    }
    serialize(value, typeNode) {
        if (isNonNullTypeNode(typeNode)) {
            return this.serialize(value, typeNode.type);
        }
        if (isListTypeNode(typeNode)) {
            return mapIfArray(value, (v) => this.serialize(v, typeNode.type));
        }
        return this.serializeNamed(value, typeNode);
    }
    serializeNamed(value, typeNode) {
        const typeName = typeNode.name.value;
        const schemaType = this.schema.getType(typeName);
        return schemaType && isInputType(schemaType) ? this.serializer.serialize(value, schemaType) : value;
    }
}
export const withScalars = (pars) => {
    return new ScalarApolloLink(pars);
};
