import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DefaultLayout from '../components/layout/DefaultLayout';
import ProjectFormCard, {
  ProjectFormBag,
  ProjectFormValues,
} from '../components/projects/ProjectFormCard';
import {
  useProjectQuery,
  useUpdateProjectMutation,
  useUsersQuery,
} from '../gql';

export interface EditProjectPageProps {}

const EditProjectPage = (props: EditProjectPageProps) => {
  let { id: idStr } = useParams<{ id: string }>();
  const projectId = parseInt(idStr!, 10);
  const {
    data: projectData,
    loading: projectLoading,
    // error: projectError,
  } = useProjectQuery({
    variables: { id: projectId },
  });
  const { data: usersData, loading: usersLoading } = useUsersQuery();
  const [updateProject] = useUpdateProjectMutation();
  const navigate = useNavigate();
  /** */
  const project = projectData && projectData.project;
  const users = usersData && usersData.users;
  const loading = projectLoading || usersLoading;

  /** */
  const onSubmit = async (values: ProjectFormValues, bag: ProjectFormBag) => {
    try {
      await updateProject({
        variables: { input: { id: projectId, ...values } },
      });
      navigate(`/projects/${projectId}`);
    } catch (error) {
      bag.setStatus({ apolloError: error });
      bag.setSubmitting(false);
    }
  };

  /** */
  return (
    <DefaultLayout
      title={`Edition du projet ${project ? project.name : ''}`}
      loading={loading}
    >
      {project && (
        <>
          <ProjectFormCard
            initialValues={{
              name: project.name,
              pitch: project.pitch || undefined,
              userIds: (project.users && project.users.map((u) => u.id)) || [],
            }}
            users={users || []}
            onSubmit={onSubmit}
          />
        </>
      )}
    </DefaultLayout>
  );
};

export default EditProjectPage;
