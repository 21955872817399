import { ServerError, ServerParseError } from '@apollo/client';
import React from 'react';
import AlertError from './AlertError';

export interface ApolloNetworkErrorAlertProps {
  networkError: Error | ServerParseError | ServerError;
}
const ApolloNetworkErrorAlert = ({
  networkError,
}: ApolloNetworkErrorAlertProps) => {
  const statusCode =
    'statusCode' in networkError ? `${networkError.statusCode}` : '';
  return <AlertError message={statusCode} details={networkError.message} />;
};

export default ApolloNetworkErrorAlert;
