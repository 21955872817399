import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import CakeIcon from '@material-ui/icons/Cake';
import CloseIcon from '@material-ui/icons/Close';
import GavelIcon from '@material-ui/icons/Gavel';
import MailIcon from '@material-ui/icons/Mail';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useMe } from '../auth/me-context';
import DefaultLayout from '../components/layout/DefaultLayout';
import UserAvatar from '../components/users/UserAvatar';
import UserPasswordFormCard, {
  UserPasswordFormBag,
  UserPasswordFormValues,
} from '../components/users/UserPasswordFormCard';
import { useChangeUserPasswordMutation, useUserQuery } from '../gql';
import { formatFr } from '../utils/date';
import { formatContratDateRange } from '../utils/format';

const UserPage = () => {
  const me = useMe();
  let { id: idStr } = useParams<{ id: string }>();
  const userId = parseInt(idStr!, 10);
  const {
    data: userData,
    loading: userLoading,
    // error: userError,
  } = useUserQuery({
    variables: { id: userId },
  });
  const [changePassword, { loading: changePasswordLoading }] =
    useChangeUserPasswordMutation();
  /** */
  const [passwordDialogOpened, setPasswordDialogOpened] = React.useState(false);
  const user = userData && userData.user;
  const itIsMe = user && user.id === me.id;

  /** */
  const openPasswordDialog = () => {
    setPasswordDialogOpened(true);
  };

  const closePasswordDialog = () => {
    if (changePasswordLoading) return;
    setPasswordDialogOpened(false);
  };

  /** */
  const onChangePassword = async (
    { password, newPassword }: UserPasswordFormValues,
    bag: UserPasswordFormBag,
  ) => {
    try {
      await changePassword({
        variables: { input: { userId, oldPassword: password, newPassword } },
      });
      closePasswordDialog();
    } catch (error) {
      bag.setStatus({ apolloError: error });
      bag.setSubmitting(true);
    }
  };

  /** */
  return (
    <DefaultLayout
      loading={userLoading}
      title={
        user && (
          <Box display="flex" alignItems="center">
            <Box mr={2}>
              <UserAvatar user={user} size="big" />
            </Box>
            <Typography variant="h2">{`${user.firstName} ${user.lastName}`}</Typography>
          </Box>
        )
      }
    >
      {user && (
        <>
          <Box my={4}>
            <Card>
              <List dense>
                <ListItem>
                  <ListItemIcon>
                    <MailIcon />
                  </ListItemIcon>
                  <ListItemText primary={user.email}></ListItemText>
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <CakeIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={formatFr(user.birthDate, 'd MMMM yyyy')}
                  ></ListItemText>
                </ListItem>

                {itIsMe && (
                  <ListItem button onClick={openPasswordDialog}>
                    <ListItemIcon>
                      <VpnKeyIcon />
                    </ListItemIcon>
                    <ListItemText primary="Mot de passe"></ListItemText>
                  </ListItem>
                )}
              </List>
            </Card>
          </Box>

          <Box my={4}>
            <Card>
              <CardHeader
                title={
                  <Box display="flex" alignItems="center">
                    <Box mr={2} display="flex" alignItems="center">
                      <GavelIcon />
                    </Box>
                    <Typography variant="h5">Contrat(s)</Typography>
                  </Box>
                }
              />

              {user.contracts.map((contract) => (
                <CardContent key={contract.id}>
                  <Typography variant="h6">
                    {formatContratDateRange(contract)}
                  </Typography>

                  <Box my={1} color="text.secondary">
                    <Typography>
                      {`${contract.weeklyWorkingTime / 60} heures / semaine`}
                    </Typography>
                  </Box>

                  <Box
                    my={1}
                    display="flex"
                    alignItems="center"
                    color="text.secondary"
                  >
                    <Box mr={1} display="flex" alignItems="center">
                      <TimelapseIcon />
                    </Box>
                    <Typography>
                      {`~ ${Math.round(
                        contract.availableRecoveryTime / 3600,
                      )} heures à récupérer`}
                    </Typography>
                  </Box>

                  {/* <Box
                    my={1}
                    display="flex"
                    alignItems="center"
                    color="text.secondary"
                  >
                    <Box mr={1} display="flex" alignItems="center">
                      <Brightness5Icon />
                    </Box>
                    <Typography>
                      {`~ ${Math.round(
                        contract.vacationDaysEarned -
                          contract.vacationDaysSpent,
                      )} jours de congés disponibles à la date d'aujourd'hui`}
                    </Typography>
                  </Box> */}
                </CardContent>
              ))}
            </Card>
          </Box>

          {itIsMe && (
            <>
              <Dialog
                fullWidth
                open={passwordDialogOpened}
                onClose={closePasswordDialog}
              >
                <DialogTitle>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6">
                      Changer de mot de passe
                    </Typography>
                    <Box>
                      <IconButton onClick={closePasswordDialog}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </DialogTitle>
                <UserPasswordFormCard onSubmit={onChangePassword} />
              </Dialog>
            </>
          )}
        </>
      )}
    </DefaultLayout>
  );
};

export default UserPage;
