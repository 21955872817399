import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { addMonths, subMonths } from 'date-fns';
import React from 'react';
import { formatFr } from '../../utils/date';

export interface CalendarNavProps {
  date: Date;
  onNavigate: (newDate: Date) => void;
}

const useStyles = makeStyles((theme) => ({
  currentDate: {
    textTransform: 'capitalize',
  },
}));

const CalendarNav = ({ date, onNavigate }: CalendarNavProps) => {
  const classes = useStyles();

  /** */
  const onTodayClick = () => {
    onNavigate(new Date());
  };

  const onPrevClick = () => {
    onNavigate(subMonths(date, 1));
  };
  const onNextClick = () => {
    onNavigate(addMonths(date, 1));
  };

  /** */
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      px={4}
      py={2}
    >
      <Box display="flex" alignItems="center" mr={4}>
        <Typography className={classes.currentDate}>
          {formatFr(date, 'MMMM yyyy')}
        </Typography>
      </Box>

      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Box mr={1}>
          <Button size="small" variant="outlined" onClick={onTodayClick}>
            Aujourd'hui
          </Button>
        </Box>

        <Box>
          <IconButton onClick={onPrevClick}>
            <NavigateBeforeIcon />
          </IconButton>
        </Box>

        <Box>
          <IconButton onClick={onNextClick}>
            <NavigateNextIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default CalendarNav;
