import {
  Box,
  Dialog,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';
import { getWeek } from 'date-fns';

export interface SosDialogProps {
  open: boolean;
  onClose: () => void;
}

const devs = [
  {
    name: 'François',
    phone: '06 81 66 59 28',
  },
  { name: 'Fabien', phone: '07 49 48 12 08' },
  { name: 'Corentin', phone: '06 66 47 34 38' },
];

export const SosDialog = ({ open, onClose }: SosDialogProps) => {
  var currentDate = new Date();
  const index = getWeek(currentDate) % devs.length;


  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <Box p={2}>
        <Box mb={2}>
          <Typography variant="h4">SOS DEV</Typography>
        </Box>

        <Typography>
          En cas d'urgence, appelez un développeur en respectant cet ordre
        </Typography>

        <List>
          {[...devs.slice(index), ...devs.slice(0, index)].map((dev) => (
            <ListItem key={dev.name}>
              <ListItemText primary={dev.name} secondary={dev.phone} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Dialog>
  );
};

export default SosDialog;